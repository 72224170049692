import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.shaku.tech",
  // baseURL: "https://api.shaku.tech/Shaku/api/v1",
});

// Add the response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if the error is a 401 Unauthorized error and the current path is dashboard or its sub-routes
    if (error.response && error.response.status === 401 && isDashboardRoute()) {
      localStorage.removeItem("userInfo");
      window.location.href = "/auth/login";
    }

    return Promise.reject(error);
  }
);

// Helper function to check if the current route is dashboard or its sub-routes
function isDashboardRoute() {
  const currentPath = window.location.pathname;
  return currentPath.startsWith("/dashboard");
}

export default instance;

import React, { useState } from "react";
import Select, { components } from "react-select";
import { defaultCountries, FlagImage } from "react-international-phone";
import "react-international-phone/style.css";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";

interface CountrySelectorProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  success?: boolean;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({ label, value, onChange, error, success }) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = defaultCountries.map((c) => ({
    value: c[0],
    label: c[0],
    iso2: c[1],
  }));

  const handleChange = (selectedOption: any) => {
    onChange(selectedOption.value);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#F9F9FA",
      borderColor: "#e4e3e5",
      borderRadius: "0.5rem",
      minHeight: "3.5rem",
      boxShadow: "none",
      transition: "0.6s",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#a3a2aa",
        boxShadow: "0px 0px 0px 4px #F9F9FA",
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#e3e4f8" : state.isFocused ? "#EDEDFA" : "#F9F9FA",
      fontSize: "15px",
      lineHeight: "18px",
      borderRadius: "0.5rem",
      cursor: "pointer",
      color: "#484554",
      marginBottom: "0.5rem",
      "&:last-child": {
        marginBottom: 0,
      },
      "&:active": {
        backgroundColor: "#EDEDFA",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#F9F9FA",
      padding: "0.5rem",
      borderRadius: "0.5rem",
      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: "15rem",
      backgroundColor: "#F9F9FA",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        margin: "0.5rem 0",
        width: "0.75rem",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EDEDFA",
        borderRadius: "1rem",
      },
      "&::-webkit-scrollbar-thumb": {
        position: "relative",
        background: "#FFFFFF",
        border: "1px solid #E3E4F8",
        borderRadius: "1rem",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#FFFFFF",
      },
    }),
    menulist: (provided: any) => ({
      ...provided,
      backgroundColor: "#F9F9FA",
      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#484554",
      fontSize: "15px",
      lineHeight: "18px",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "15px",
      lineHeight: "18px",
      color: "#A3A2AA",
    }),
  };

  const formatOptionLabel = ({ value, iso2 }: { value: string; iso2: string }) => (
    <div className="flex items-center gap-2">
      <FlagImage iso2={iso2} />
      <span>{value}</span>
    </div>
  );

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <ArrowDown2 variant="Broken" className="size-5 text-closed-shutter-300" /> : <ArrowRight2 variant="Broken" className="size-5 text-closed-shutter-300" />}
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="flex flex-col gap-1 ">
      <label className="flex items-center gap-2 text-xs font-bold text-closed-shutter-300 ">{label}</label>
      <Select
        value={options.find((option) => option.value === value)}
        onChange={handleChange}
        options={options}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        isSearchable={true}
        placeholder="Search country..."
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default CountrySelector;

import { Box, Code } from "iconsax-react";
import { FREE_TRIAL } from "../../utilities/defaults/free-trial/plans";
import MainButton from "../button/MainButton";

const FreeTrial: React.FC = () => {
  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-6">
        <h2 className="font-bold text-closed-shutter-300">Free Trial</h2>
      </div>

      <div className="flex flex-col w-full h-full gap-6 p-6 overflow-y-auto">
        <div className="relative w-full overflow-hidden rounded-md">
          <img src="/assets/image/free-trial/header.svg" alt="Free Trial Header" className="object-contain min-w-full" />
          <span className="absolute inset-0 grid text-lg font-bold text-gorgonzola-blue-50 size-full place-content-center">Start Your Free Trial</span>
        </div>

        <div className="flex justify-center gap-[4.375rem]">
          {FREE_TRIAL.map((plan) => (
            <div key={plan.id} className="flex flex-col items-center gap-4 p-4 w-[18.25rem] border rounded-md border-gorgonzola-blue-50">
              <div className="overflow-hidden rounded">
                <img src={plan.image} alt={plan.image_alt} className="object-contain min-w-full" />
              </div>

              <div className="flex flex-col divide-y divide-gorgonzola-blue-50 *:py-4">
                <h2 className="font-bold text-center text-closed-shutter-300">{plan.title}</h2>
                <p className="text-closed-shutter-300/70">{plan.description}</p>
              </div>
              <div className="flex justify-center gap-6">
                <MainButton type="secondary" to="https://api.shaku.tech/docs.html" passHref passHrefTarget="_blank">
                  <Code className="size-6" />
                  <span>Api</span>
                </MainButton>

                <MainButton type="primary" to={`/dashboard/free-trial/${plan.sdk_href}`}>
                  <Box className="size-6" />
                  <span>Sdk's</span>
                </MainButton>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FreeTrial;

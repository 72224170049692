import React from "react";
import BalanceCard from "./BalanceCard";
import TransactionChart from "./TransactionChart";
import LastTransaction from "./LastTransaction";
import { Transaction } from "../../utilities/types/mianDashboard/types";

interface BalanceOverviewProps {
  balance: number;
  transactions: Transaction[];
}

const BalanceOverview: React.FC<BalanceOverviewProps> = ({ balance, transactions }) => {
  return (
    <div className="flex flex-col justify-center flex-grow gap-4">
      <div className="w-full h-full p-4 rounded-md bg-gorgonzola-blue-50">
        <div className="flex flex-col justify-between gap-7">
          <BalanceCard balance={balance} />
          {transactions.length > 0 ? (
            <div className="flex justify-between w-full">
              <LastTransaction action={transactions[transactions.length - 1].action} amount={transactions[transactions.length - 1].amount} />
              <TransactionChart transactions={transactions} />
            </div>
          ) : (
            <div className="text-xs text-center text-closed-shutter-300">No transaction data available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BalanceOverview;

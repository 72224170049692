import React from "react";
import { useBodyMeasurementServiceContext } from "../../../contexts/BodyMeasurementServiceContext";
import { BODY_MEASUREMENT_SERVICE_STEPS as steps } from "../../../utilities/defaults/free-trial/body-measurement-service-steps";

const BodyMeasurementServiceStepIndicator: React.FC = () => {
  const { currentStep } = useBodyMeasurementServiceContext();

  return (
    <div className="flex justify-center w-full ">
      <div className="flex justify-center overflow-hidden w-fit">
        {steps.map((step, index) => (
          <div key={step.id} className={`relative flex rounded-full items-center justify-center *:flex *:flex-col`}>
            <div
              className={`flex flex-col items-center gap-2 min-w-[10rem] ${
                // pathname.includes(step.id) ? "" : currentStep >= steps.findIndex((s) => s.id === step.id) ? "text-gorgonzola-blue-300" : "text-gorgonzola-blue-200"
                currentStep === steps.findIndex((s) => s.id === step.id) || currentStep > steps.findIndex((s) => s.id === step.id)
                  ? "text-shindig-300"
                  : currentStep + 1 === steps.findIndex((s) => s.id === step.id)
                  ? "text-gorgonzola-blue-300"
                  : "text-gorgonzola-blue-200"
              }`}
            >
              {index !== steps.length - 1 ? (
                <span
                  className={`absolute top-6 translate-x-1/2 w-full h-0.5 z-10 ${
                    currentStep === steps.findIndex((s) => s.id === step.id) || currentStep > steps.findIndex((s) => s.id === step.id)
                      ? "bg-shindig-300"
                      : currentStep + 1 === steps.findIndex((s) => s.id === step.id)
                      ? "bg-gorgonzola-blue-300"
                      : "bg-gorgonzola-blue-200"
                  }`}
                />
              ) : null}
              <div
                className={`size-12 bg-white grid place-content-center border rounded-full z-20 ${
                  currentStep === steps.findIndex((s) => s.id === step.id) || currentStep > steps.findIndex((s) => s.id === step.id)
                    ? "border-shindig-300"
                    : currentStep + 1 === steps.findIndex((s) => s.id === step.id)
                    ? "border-gorgonzola-blue-300"
                    : "border-gorgonzola-blue-200"
                }`}
              >
                <span>{React.cloneElement(step.svg, { className: "size-6" })}</span>
              </div>

              <span className="text-xs capitalize">{step.label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default BodyMeasurementServiceStepIndicator;

import React, { useEffect } from "react";
import { useAutoTaggingServiceContext } from "../../../../contexts/AutoTaggingServiceContext";
import AutoTaggingServiceLoader from "../AutoTaggingServiceLoader";
import AutoTaggingServiceImageDisplay from "../AutoTaggingServiceImageDisplay";
import ErrorDisplay from "../../ErrorDisplay";

const AutoTagging: React.FC = () => {
  const { submitAutoTagging, error, setError } = useAutoTaggingServiceContext();

  useEffect(() => {
    submitAutoTagging();
  }, [submitAutoTagging]);

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        onRetry={() => {
          setError(null);
          submitAutoTagging();
        }}
      />
    );
  }

  return (
    <div className="flex flex-col items-center gap-32 w-[33.8%]">
      <AutoTaggingServiceLoader />
      <div className="flex flex-col items-center gap-10">
        <span className="font-bold text-center capitalize text-closed-shutter-300">Clothes Recognition in progress...</span>
        <AutoTaggingServiceImageDisplay />
      </div>
    </div>
  );
};

export default AutoTagging;

import React, { createContext, useState, useContext } from "react";
import { submitSizeMeasurement } from "../utilities/api/bodyMeasurementService";
import { AuthContext } from "./AuthContext";
import { MeasurementResult } from "../utilities/types/measurement-data/types";

// Interface for the context value
interface BodyMeasurementServiceContextValue {
  frontImage: string | null;
  sideImage: string | null;
  height: number;
  currentStep: number;
  canProceedToNextStep: boolean;
  measurementData: MeasurementResult | null;
  error: string | null;
  setError: (error: string | null) => void;
  handlePageChange: (page: number) => void;
  handleFrontImageUpload: (image: File | undefined) => void;
  handleSideImageUpload: (image: File | undefined) => void;
  setHeight: (height: number) => void;
  setCanProceedToNextStep: (value: boolean) => void;
  submitMeasurement: () => Promise<void>;
  resetBodyMeasurementService: () => void;
}

// Interface for the provider props
interface BodyMeasurementServiceProviderProps {
  children: React.ReactNode;
}

// Create the context
export const BodyMeasurementServiceContext = createContext<BodyMeasurementServiceContextValue | undefined>(undefined);

// Provider component
export const BodyMeasurementServiceProvider: React.FC<BodyMeasurementServiceProviderProps> = ({ children }) => {
  // State variables
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [sideImage, setSideImage] = useState<string | null>(null);
  const [height, setHeight] = useState(180);
  const [currentStep, setCurrentStep] = useState(0);
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [measurementData, setMeasurementData] = useState<MeasurementResult | null>(null);
  const { accessToken } = useContext(AuthContext);

  // Function to handle page change
  const handlePageChange = (page: number) => {
    setCurrentStep(page);
    setCanProceedToNextStep(false);
  };

  // Function to handle front image upload
  const handleFrontImageUpload = async (image: File | undefined) => {
    if (image) {
      const base64 = await convertToBase64(image);
      setFrontImage(base64);
      setCanProceedToNextStep(sideImage !== null);
    }
  };

  // Function to handle side image upload
  const handleSideImageUpload = async (image: File | undefined) => {
    if (image) {
      const base64 = await convertToBase64(image);
      setSideImage(base64);
      setCanProceedToNextStep(frontImage !== null);
    }
  };

  const submitMeasurement = async () => {
    if (!frontImage || !sideImage || !height || !accessToken) {
      setError("Missing required information for measurement");
      return;
    }

    try {
      const response = await submitSizeMeasurement(accessToken, {
        present_height: height.toString(),
        img_full_view_body: frontImage,
        img_side_view_body: sideImage,
      });
      if (response.ok) {
        setMeasurementData(response.data.result);
        setCanProceedToNextStep(true);
        setCurrentStep(3);
        setError(null);
      } else {
        setError(response.message || "Failed to process measurement");
        setCurrentStep(1);
      }
    } catch (error) {
      console.error("Error submitting size measurement:", error);
      setError("An error occurred during measurement. Please try again.");
      setCurrentStep(1);
    }
  };

  // Function to reset the Free Trial
  const resetBodyMeasurementService = () => {
    setFrontImage(null);
    setSideImage(null);
    setHeight(184);
    setCurrentStep(0);
    setCanProceedToNextStep(false);
    setMeasurementData(null);
  };

  // Helper function to convert file to base64
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      // Handle successful file read
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve(base64.split(",")[1]);
      };

      // Handle error while reading file
      reader.onerror = (error) => {
        console.error("Error converting to base64:", error);
        reject(error);
      };
    });
  };
  // Return the context value
  return (
    <BodyMeasurementServiceContext.Provider
      value={{
        frontImage,
        sideImage,
        height,
        currentStep,
        canProceedToNextStep,
        handlePageChange,
        handleFrontImageUpload,
        handleSideImageUpload,
        error,
        setError,
        setHeight,
        setCanProceedToNextStep,
        submitMeasurement,
        resetBodyMeasurementService,
        measurementData,
      }}
    >
      {children}
    </BodyMeasurementServiceContext.Provider>
  );
};

// Custom hook to consume the context
export const useBodyMeasurementServiceContext = () => {
  const context = useContext(BodyMeasurementServiceContext);
  if (!context) {
    throw new Error("useBodyMeasurementServiceContext must be used within a BodyMeasurementServiceProvider");
  }
  return context;
};

import React from "react";
import { CardPos } from "iconsax-react";
import { Link } from "react-router-dom";

interface BalanceCardProps {
  balance: number;
}

const BalanceCard: React.FC<BalanceCardProps> = ({ balance }) => (
  <div className="relative flex items-start justify-between gap-4 ps-4">
    <span className="absolute inset-0 w-1 h-full rounded-lg bg-gorgonzola-blue-300" />
    <div className="flex flex-col gap-4 text-base font-bold">
      <h4 className="text-closed-shutter-300">Balance</h4>
      <span className="text-gorgonzola-blue-300">$ {balance.toFixed(2)}</span>
      {/* <span className="text-gorgonzola-blue-300">$ {balance}</span> */}
    </div>
    <Link to={"/dashboard/payment"}>
      <div className="flex gap-2 *:size-10 *:grid *:place-content-center">
        <span className="transition-colors rounded-md duration-600 bg-gorgonzola-blue-100 hover:bg-gorgonzola-blue-200 text-gorgonzola-blue-300 hover:text-gorgonzola-blue-500 group">
          <CardPos className="transition-transform size-6 group-hover:scale-105 duration-600" variant="Bold" />
        </span>
      </div>
    </Link>
  </div>
);

export default BalanceCard;

import instance from "./axiosInstance";

export interface ServiceHistoryItem {
  id: number;
  service_name: string;
  message: string | null;
  status: string;
  response: any;
  created_at: number;
}

interface ServiceHistoryResponse {
  ok: boolean;
  message: string;
  data: {
    data: ServiceHistoryItem[];
    current_page: number;
    total: number;
    last_page: number;
    count_results: number;
    per_page: string;
  };
  status_code: number;
}

export const getServicesUsageHistory = async (accessToken: string, page: number = 1, perPage: number = 10, userId?: number): Promise<ServiceHistoryResponse> => {
  try {
    const response = await instance.get<ServiceHistoryResponse>("/api/v1/history/services", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        page,
        per_page: perPage,
        ...(userId && { user_id: userId }),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching services usage history:", error);
    throw error;
  }
};

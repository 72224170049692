
import React from "react";
import { MeasurementResult } from "../../utilities/types/measurement-data/types";

interface BodyMeasurementResultProps {
  measurementData: MeasurementResult;
}

const BodyMeasurementResult: React.FC<BodyMeasurementResultProps> = ({ measurementData }) => {
  const renderMeasurement = (label: string, value: number | string) => (
    <div className="flex flex-col gap-1 p-3 rounded-xl bg-gorgonzola-blue-100">
      <span className="text-xs text-closed-shutter-300/70">{label}</span>
      <span className="text-base font-bold text-closed-shutter-300">{typeof value === "number" ? `${value.toFixed(2)} cm` : value}</span>
    </div>
  );

  return (
    <div className="grid grid-cols-3 gap-4 ">
      {renderMeasurement("Shoulder", measurementData.Shoulder)}
      {renderMeasurement("Chest", measurementData.Chest)}
      {renderMeasurement("Waist", measurementData.Waist)}
      {renderMeasurement("Hips", measurementData.Hips)}
      {renderMeasurement("Neck", measurementData.Neck)}
      {renderMeasurement("Under Chest", measurementData.UnderChest)}
      {renderMeasurement("Wrist", measurementData.Wrist)}
      {renderMeasurement("Knee", measurementData.Knee)}
      {renderMeasurement("Upper Body", measurementData.UpperBody)}
      {renderMeasurement("Lower Body", measurementData.LowerBody)}
      {renderMeasurement("Arm Length", measurementData.ArmLength)}
    </div>
  );
};

export default BodyMeasurementResult;

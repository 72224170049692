import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../loading/Loader";
import { enqueueSnackbar } from "notistack";
import { ArrowLeft, Printer } from "iconsax-react";
import MainButton from "../button/MainButton";
import { InvoiceInfo } from "../../utilities/types/wallet/types";
import { getInvoiceInfo } from "../../utilities/api/walletService";
import StatusBadge from "../table/StatusBadge";
import Breadcrumb from "../breadcrumb/Breadcrumb";

const InvoiceDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { accessToken } = useAuth();
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchInvoiceInfo = async () => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getInvoiceInfo(accessToken, String(id));
        if (response.ok) {
          setInvoiceInfo(response.data);
        } else {
          enqueueSnackbar(response.message || "Unable to retrieve invoice details. Please try again.", { variant: "error" });
        }
      } catch (error) {
        console.error("Error fetching invoice details:", error);
        enqueueSnackbar("An error occurred while retrieving invoice details. Please try again later.", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceInfo();
  }, [accessToken, id]);

  const handlePrint = () => {
    if (printRef.current) {
      const printContents = printRef.current.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = `
        <html>
          <head>
            <title>Print Invoice</title>
            <style>
              @media print {
                body { font-family: Arial, sans-serif; }
                .no-print { display: none; }
              }
            </style>
          </head>
          <body>${printContents}</body>
        </html>
      `;

      window.print();
      document.body.innerHTML = originalContents;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center w-full p-6">
        <Loader />
      </div>
    );
  }

  if (!invoiceInfo) {
    return <div className="grid p-6 place-content-center">No invoice information available.</div>;
  }

  return (
    <div className="flex flex-col divide-y cursor-auto select-text divide-gorgonzola-blue-50">
      {/* <div className="w-full p-6">
        <h2 className="font-bold text-closed-shutter-300">
          <span className="text-gorgonzola-blue-300">Payment id: </span>#{invoiceInfo.to.id}
        </h2>
      </div> */}
      <Breadcrumb isId customTag={invoiceInfo.to.id.toString()} />

      <div className="flex flex-col items-center gap-16 p-6" ref={printRef}>
        <div className="flex flex-col items-center gap-8 bg-white border border-gorgonzola-blue-50 p-4 rounded-md w-fit min-w-[33.5625rem] overflow-y-auto">
          <div className="grid w-full grid-cols-3 gap-4">
            <div className="flex flex-col items-center col-span-2 gap-4">
              <div className="w-full overflow-hidden rounded min-w-max *:min-w-full ">
                <img src="/assets/image/plan/ConfirmationModalLogo.png" alt="Confirmation Modal Logo" />
              </div>
              <div className="flex flex-col gap-1 p-4 bg-[#ededfa80] w-full rounded border border-gorgonzola-blue-100 *:flex *:flex-col *:gap-1">
                <div>
                  <h5 className="text-2xs text-closed-shutter-300/70">Invoice to:</h5>
                  <span className="text-xs capitalize text-gorgonzola-blue-300 ">{invoiceInfo.from?.company_name}</span>
                </div>
                <div>
                  <h5 className="text-2xs text-closed-shutter-300/70">Your email:</h5>
                  <span className="text-xs text-closed-shutter-300">{invoiceInfo.to?.email}</span>
                </div>
                <div>
                  <h5 className="text-2xs text-closed-shutter-300/70">Your name:</h5>
                  <span className="text-xs capitalize text-closed-shutter-300">{`${invoiceInfo.to?.first_name || ""} ${invoiceInfo.to?.last_name || ""}`}</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between p-4 border rounded border-gorgonzola-blue-100 min-h-max">
              <div className="flex flex-col items-center w-full gap-2">
                <div className="flex flex-col gap-1 p-3 bg-[#ededfa80] rounded w-full">
                  <h5 className="text-2xs text-closed-shutter-300/70">Invoice number:</h5>
                  <span className="text-xs capitalize text-closed-shutter-300">{invoiceInfo.to.id}</span>
                </div>
                <div className="flex flex-col gap-1 p-3 bg-[#ededfa80] rounded w-full">
                  <h5 className="text-2xs text-closed-shutter-300/70">Issued:</h5>
                  <span className="text-xs capitalize text-closed-shutter-300">
                    {new Date(invoiceInfo.created_at * 1000).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                  </span>
                </div>
              </div>

              <span className="w-full h-px bg-gorgonzola-blue-100" />
              <div className="flex flex-col justify-between gap-2">
                <h5 className="text-2xs text-closed-shutter-300/70">Amount due:</h5>
                <span className="font-bold capitalize text-closed-shutter-300">${parseInt(invoiceInfo.price).toFixed(2)}</span>
                <span className="text-2xs text-closed-shutter-300/70">
                  {new Date(invoiceInfo.created_at * 1000 + 30 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center w-full gap-2">
            <div className="flex items-center w-full gap-2 ">
              <div className="inline-flex items-center gap-2">
                <h5 className="text-2xs text-closed-shutter-300/70">Status:</h5>
                <StatusBadge
                  isSuccess={invoiceInfo.status === "paid" ? true : invoiceInfo.status === "new" ? null : false}
                  statusText={invoiceInfo.status}
                  customClass="w-24 min-h-[2.125rem]"
                />
              </div>

              <div className="inline-flex items-center gap-2">
                <h5 className="text-2xs text-closed-shutter-300/70">P.method:</h5>
                <div className="flex items-center w-24 min-h-[2.125rem] px-2 py-1 border rounded-md gap-2 bg-gorgonzola-blue-50 border-gorgonzola-blue-200">
                  <img src={invoiceInfo.pay_operator_logo} alt={invoiceInfo.pay_operator} className="w-8 h-6" />
                  <span className="text-xs capitalize text-closed-shutter-300">{invoiceInfo.pay_operator}</span>
                </div>
              </div>
              {invoiceInfo.from.country ? (
                <div className="inline-flex items-center gap-2">
                  <h5 className="text-2xs text-closed-shutter-300/70">Country:</h5>
                  <div className="flex items-center w-24 min-h-[2.125rem] px-2 py-1 border rounded-md gap-2 bg-gorgonzola-blue-50 border-gorgonzola-blue-200">
                    <span className="text-xs capitalize text-closed-shutter-300">{invoiceInfo.from.country}</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-full">
            <div className="flex justify-between border mb-2 text-2xs gap-3 text-[#868DA6] w-full rounded">
              <span className="flex-grow p-3 text-left">Description</span>
              <div className="inline-flex gap-2">
                <span className="px-4 py-3 text-center">Requests</span>
                <span className="px-4 py-3 text-center">Price</span>
                <span className="px-4 py-3 text-center">Total</span>
              </div>
            </div>
            <div className="flex justify-between text-xs gap-3 w-full *:text-center">
              <span className="flex-grow p-3 font-bold rounded bg-gorgonzola-blue-50 text-closed-shutter-300">{invoiceInfo.product.name}</span>
              <div className="inline-flex gap-2">
                <span className="p-3 bg-transparent border rounded border-gorgonzola-blue-50 text-closed-shutter-300/70">{invoiceInfo.product.requests}</span>
                <span className="p-3 bg-transparent border rounded border-gorgonzola-blue-50 text-closed-shutter-300/70">${parseInt(invoiceInfo.price).toFixed(2)}</span>
                <span className="p-3 rounded bg-gorgonzola-blue-50 text-closed-shutter-300">${parseInt(invoiceInfo.total).toFixed(2)}</span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-4 py-3 border rounded">
            <span className="text-xs text-closed-shutter-300/70">Invoice Total</span>
            <span className="font-bold text-gorgonzola-blue-300">${parseInt(invoiceInfo.total).toFixed(2)}</span>
          </div>
        </div>

        <div className="flex justify-between w-[88%] no-print">
          <MainButton type="secondary" to="/dashboard/payment">
            <ArrowLeft className="size-6" variant="Broken" />
            Back
          </MainButton>
          <MainButton type="primary" onClick={handlePrint}>
            Print
            <Printer className="size-6" variant="Broken" />
          </MainButton>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;

import React from "react";

import MainDashboard from "./MainDashboard";
import { DashboardProvider } from "../contexts/DashboardContext";

const DashboardContainer: React.FC = () => {
  return (
    <DashboardProvider>
      <MainDashboard />
    </DashboardProvider>
  );
};

export default DashboardContainer;

import instance from "./axiosInstance";
import { UserInfoResponse } from "../types/user/types";

export const getUserInfo = async (accessToken: string): Promise<UserInfoResponse> => {
  try {
    const response = await instance.get<UserInfoResponse>("/api/v1/auth/me", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

import { ArrowCircleDown2 } from "iconsax-react";
import { TableProps } from "../../utilities/types/support/types";

const Table = <T extends Record<string, any>>({ data, columns, onRowClick }: TableProps<T>) => {
  return (
    <div className="">
      <table className="min-w-full divide-y divide-gorgonzola-blue-100">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key as string} className="px-6 py-3 ">
                <div className="flex items-center justify-center gap-2">
                  <span className="text-xs font-medium tracking-wider text-left capitalize text-closed-shutter-300">{column.header}</span>
                  <ArrowCircleDown2 className="size-6" variant="Broken" />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="odd:*:bg-white even:*:bg-gorgonzola-blue-50/30">
          {data.map((item, index) => (
            <tr key={index} onClick={() => onRowClick && onRowClick(item)} className="transition-colors cursor-pointer duration-600 hover:bg-gorgonzola-blue-50">
              {columns.map((column) => (
                <td key={column.key as string} className="px-2 py-4 max-w-36 ">
                  <div className="flex items-center justify-center">
                    <span className=" text-closed-shutter-300 !line-clamp-1">{column.render ? column.render(item[column.key as keyof T], item) : item[column.key as keyof T]}</span>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import React, { useState } from "react";
import { Crown1 } from "iconsax-react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";

interface Plan {
  name: string;
  is_free_plan: boolean;
  label: string;
  main_price: number;
  remaining_price: number;
  apis: {
    name: string;
    real_price: number;
    paid_price: number;
    discount: number;
    total_requests: number;
    remaining_requests: number;
    remaining_requests_pre: number;
  }[];
}

interface PlanOverviewProps {
  activedOrders: { plan: Plan }[];
}

const PlanOverview: React.FC<PlanOverviewProps> = ({ activedOrders }) => {
  const { userInfo } = useGlobalInfo();
  const [currentPlanIndex, setCurrentPlanIndex] = useState(0);
  const currentPlan = activedOrders[currentPlanIndex]?.plan;
  console.log(activedOrders);

  const renderProgressBar = (plan: Plan) => {
    const usagePercentage = plan.apis[0].remaining_requests_pre;

    return (
      <div className="relative w-full h-2.5 rounded-full bg-white">
        <div
          className={`absolute inset-0 h-full rounded-full bg-[linear-gradient(90deg,_#00A991_44.5%,_#008774_100%)] z-40`}
          style={{ width: `${usagePercentage}%` }}
          aria-label={`${usagePercentage}% of ${plan.name} used`}
        />

        <div className="absolute inset-0 w-full mx-auto">
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: `${Math.min(0)}%` }}
            transition={{ duration: 1, delay: 1 }}
            className="absolute w-16 -translate-y-full bottom-3.5"
            style={{ right: "0" }}
          >
            <div className="relative w-fit">
              <img src="/assets/image/plan/persentage_bg.svg" alt="percentage background" className="w-16" />
              <span className="absolute inset-0 flex items-center justify-center text-xs font-bold">{usagePercentage}%</span>
            </div>
          </motion.div>
        </div>
      </div>
    );
  };

  if (!activedOrders || activedOrders.length === 0) {
    return (
      <div className="w-full p-4 overflow-hidden rounded-md bg-shindig-50">
        <div className="flex flex-col gap-12">
          <div className="relative flex justify-between ps-4">
            <span className="absolute inset-0 w-1 h-full rounded-lg bg-shindig-300" />
            <div className="flex flex-col w-full gap-4">
              <div className="flex justify-between">
                <h4 className="text-base font-bold text-closed-shutter-300 line-clamp-1">No Active Plan</h4>
                <Link to="/dashboard/plans" className="p-2 transition-colors rounded-md group duration-600 bg-slap-happy-50 hover:bg-slap-happy-200 text-slap-happy-300 hover:text-slap-happy-500">
                  <Crown1 className="transition-transform size-6 group-hover:scale-105 duration-600" variant="Bold" />
                </Link>
              </div>
              <p className="text-xs text-closed-shutter-200">You don't have any active plans. Consider upgrading to access more features.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-grow w-full p-4 overflow-hidden rounded-md bg-shindig-50">
      <div className="flex flex-col gap-12">
        <div className="relative flex items-start justify-between h-fit ps-4">
          <span className="absolute inset-0 w-1 h-full rounded-lg bg-shindig-300" />
          <div className="relative flex flex-col w-full gap-4 font-bold">
            <h4 className="text-base text-closed-shutter-300 line-clamp-1">Plan</h4>
            <div className="absolute top-0 right-0 flex gap-2">
              {activedOrders.map((order, index) => (
                <button
                  key={order.plan.label}
                  className="p-2 group rounded-md bg-shindig-100 hover:bg-shindig-200 transition-colors duration-600 size-10 *:size-full "
                  onClick={() => setCurrentPlanIndex(index)}
                >
                  {order.plan.apis[0].name === "Size Measurement" ? (
                    <img src="/assets/svg/size_measurement_icon.svg" alt={order.plan.name} className="transition-transform size-6 group-hover:scale-105 duration-600" />
                  ) : (
                    <img src="/assets/svg/auto_tagging_icon.svg" alt={order.plan.name} className="transition-transform size-6 group-hover:scale-105 duration-600" />
                  )}
                </button>
              ))}
              <Link to="/dashboard/plans" className="p-2 transition-colors rounded-md group duration-600 bg-slap-happy-50 hover:bg-slap-happy-200 text-slap-happy-300 hover:text-slap-happy-500">
                <Crown1 className="transition-transform size-6 group-hover:scale-105 duration-600" variant="Bold" />
              </Link>
            </div>

            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-1 *:line-clamp-1">
                <span className={`text-shindig-300/70`}>{currentPlan.apis[0].total_requests} </span>
                <span className={`text-shindig-300`}>/ {currentPlan.apis[0].remaining_requests}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-end h-full gap-3">
          <div className="w-full ">{renderProgressBar(currentPlan)}</div>
          <div className="w-full">
            <span className="text-2xs text-closed-shutter-300 line-clamp-1">
              Dear {userInfo?.first_name}, {currentPlan.apis[0].total_requests - currentPlan.apis[0].remaining_requests} requests have been used so far
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanOverview;

import React, { useState, useRef } from "react";
import { InfoCircle, CloseCircle, DocumentDownload, Trash } from "iconsax-react";

interface AttachmentFieldProps {
  label: string;
  onChange: (file: File | null) => void;
  error?: boolean;
  required?: boolean;
  success?: boolean;
}

const AttachmentField: React.FC<AttachmentFieldProps> = ({ label, onChange, error = false, success = false, required = false }) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    onChange(selectedFile);
  };

  const handleRemoveFile = () => {
    setFile(null);
    onChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const getInputClasses = () => {
    let classes = "w-fit grid  place-content-center px-3 py-2 text-xs transition-colors duration-600 bg-[#F9F9FA] border rounded-lg outline-none min-h-14";
    if (error) classes += " border-english-vermillion-300";
    // else if (success) classes += " border-shindig-300";
    return classes;
  };

  return (
    <div className="flex flex-col gap-1">
      <label className="flex items-center gap-2 text-xs font-bold text-closed-shutter-300">
        {label}
        {required && <span className="text-english-vermillion-300">*</span>}
      </label>
      <div className="flex items-center">
        <div className={getInputClasses()}>
          <label className="flex items-center justify-center w-full h-full gap-2 cursor-pointer text-closed-shutter-200">
            <input type="file" className="hidden" onChange={handleFileChange} ref={fileInputRef} />
            <InfoCircle className="size-5" />
            <span className="text-xs ">{file ? "Change Your file" : "Select File"}</span>
          </label>
        </div>
        {file ? (
          <div className="flex items-center gap-3 p-3">
            <span className="text-xs line-clamp-1 text-closed-shutter-300">{file.name}</span>

            <div className="flex gap-2">
              {/* <button onClick={handleRemoveFile} className="transition-colors text-closed-shutter-300 hover:text-gorgonzola-blue-300 duration-600">
                <DocumentDownload className="size-6" variant="Broken" />
              </button>
              <span className="w-px h-8 rounded bg-gorgonzola-blue-200" /> */}
              <button onClick={handleRemoveFile} className="transition-colors text-closed-shutter-300 hover:text-gorgonzola-blue-300 duration-600">
                <Trash className="size-6" variant="Broken" />
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AttachmentField;

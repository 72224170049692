import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { GlobalInfoProvider } from "../contexts/GlobalInfoContext";

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <GlobalInfoProvider>
      <Outlet />
    </GlobalInfoProvider>
  );
};

export default ProtectedRoute;

import React, { createContext, useState, ReactNode } from "react";

interface SidebarContextValue {
  isLogoOpen: boolean;
  toggleLogo: () => void;
}

export const SidebarContext = createContext<SidebarContextValue | undefined>(undefined);

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const [isLogoOpen, setIsLogoOpen] = useState(false);

  const toggleLogo = () => {
    setIsLogoOpen((prevState) => !prevState);
  };

  return <SidebarContext.Provider value={{ isLogoOpen, toggleLogo }}>{children}</SidebarContext.Provider>;
};

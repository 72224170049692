export const FREE_TRIAL = [
  {
    id: 0,
    title: "Body Measurment",
    description:
      "Integrate Shaku's AI body measurement solution into your platform to offer precise sizing recommendations. Provide customers with a perfect fit using advanced technology, reducing returns and increasing satisfaction. Revolutionize the shopping experience with seamless integration.",
    image: "/assets/image/free-trial/plans/body_meaurment.png",
    image_alt: "Body Meaurment",
    api_href: "#api_href",
    sdk_href: "body-measurment",
  },
  {
    id: 1,
    title: "Auto Tagging",
    description:
      "Boost your product visibility with Shaku's advanced visual search technology. Enable customers to detect clothing features, receive similar recommendations, and conduct visual-based searches through online shops. Enhance the shopping experience and drive sales with accurate auto-tagging.",
    image: "/assets/image/free-trial/plans/auto_tagging.png",
    image_alt: "Auto Tagging",
    api_href: "#api_href",
    sdk_href: "auto-tagging",
  },
  // {
  //   id: 2,
  //   title: "Visual Clothes",
  //   description:
  //     "Boost your product visibility by implementing Shaku's advanced visual search technology, enabling customers to detect clothing features, receive similar recommendations, and conduct visual-based searches through online shops.",
  //   image: "/assets/image/free-trial/plans/auto_tagging.png",
  //   image_alt: "Visual Clothes",
  //   api_href: "#api_href",
  //   sdk_href: "visual-clothes",
  // },
];

import { ProfileTabs } from "../../types/profile-tabs/types";

export const PROFILE_TABS: ProfileTabs[] = [
  {
    title: "Basic Information",
    active: true,
  },
  {
    title: "Business Info",
    active: false,
  },
  {
    title: "Password",
    active: false,
  },
];

import instance from "./axiosInstance";

interface UpdateUserInfoParams {
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string;
}

export const updateUserInfo = async (accessToken: string, userData: UpdateUserInfoParams) => {
  try {
    const response = await instance.put("/api/v1/user/update", userData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating user info:", error);
    throw error;
  }
};

export const setProfilePicture = async (accessToken: string, imageBase64: string, userType: string = "real_user", subUserId?: string | number) => {
  try {
    const response = await instance.post(
      "/api/app/v1/profilePics/set",
      {
        img: imageBase64,
        user_type: userType,
        ...(subUserId && { sub_user_id: subUserId }),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error setting profile picture:", error);
    throw error;
  }
};

export const updateUserTourGuideStatus = async (accessToken: string) => {
  try {
    const response = await instance.put("/api/v1/providers/tourGuidePassed", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating tour guide status:", error);
    throw error;
  }
};

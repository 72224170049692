/*
 * Checks if the current path exactly matches the given route.
 * - The current URL path.
 * - The route to compare against.
 * `true` if the current path exactly matches the route, `false` otherwise.
 */
export const exactRouteMatch = (currentPath: string, route: string): boolean => {
  const currentSegments = currentPath.split("/").filter(Boolean);
  const routeSegments = route.split("/").filter(Boolean);

  if (currentSegments.length !== routeSegments.length) return false;

  return currentSegments.every((segment, index) => segment === routeSegments[index] || routeSegments[index].startsWith(":"));
};

/*
 * Checks if the current path is a sub-route of the given route.
 * - The current URL path.
 * - The route to compare against.
 * `true` if the current path is a sub-route of the given route, `false` otherwise.
 */
export const isSubRoute = (currentPath: string, route: string): boolean => {
  const currentSegments = currentPath.split("/").filter(Boolean);
  const routeSegments = route.split("/").filter(Boolean);

  if (currentSegments.length <= routeSegments.length) return false;

  return routeSegments.every((segment, index) => segment === currentSegments[index] || segment.startsWith(":"));
};

import React, { useState } from "react";
import ReusableModal from "../modal/ReusableModal";
import TourGuideStep01 from "./tour-guide-step/TourGuideStep01";
import TourGuideStep02 from "./tour-guide-step/TourGuideStep02";
import TourGuideStep03 from "./tour-guide-step/TourGuideStep03";
import TourGuideStep04 from "./tour-guide-step/TourGuideStep04";

interface TourGuideStep {
  component: React.ReactNode;
}

const tourGuideSteps: TourGuideStep[] = [
  {
    component: <TourGuideStep01 />,
  },
  {
    component: <TourGuideStep02 />,
  },
  {
    component: <TourGuideStep03 />,
  },
  {
    component: <TourGuideStep04 />,
  },
];

interface TourGuideModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TourGuideModal: React.FC<TourGuideModalProps> = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepClick = (index: number) => {
    setCurrentStep(index);
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} customModal>
      <div className="flex flex-col items-center justify-between gap-6 p-6 bg-white rounded-xl w-[22.375rem] min-h-[25rem]">
        <div className="w-full">{tourGuideSteps[currentStep].component}</div>

        <div className="flex items-center justify-between w-full">
          <div className="relative inline-flex items-center justify-center gap-[0.5625rem] w-full">
            {tourGuideSteps.map((_, index) => (
              <button
                key={index}
                onClick={() => handleStepClick(index)}
                className={`${index === currentStep ? "w-6" : "w-2.5"} ${
                  index === currentStep ? "bg-gorgonzola-blue-300" : "bg-gorgonzola-blue-200"
                } relative h-2.5  rounded-full transition-all duration-300 hover:bg-gorgonzola-blue-300/70`}
              />
            ))}
          </div>
        </div>
      </div>
    </ReusableModal>
  );
};

export default TourGuideModal;

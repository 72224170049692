import { useParams } from "react-router-dom";
import { FREE_TRIAL } from "../../utilities/defaults/free-trial/plans";
import BodyMeasurementService from "./BodyMeasurementService";
import AutoTaggingService from "./AutoTaggingService";
import VisualClothesService from "./VisualClothesService";
import { useEffect, useState } from "react";
import Loader from "../loading/Loader";

const FreeTrialDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [service, setService] = useState<typeof FREE_TRIAL[number] | null>(null);

  useEffect(() => {
    const foundService = FREE_TRIAL.find((service) => service.sdk_href === id);
    setService(foundService || null);
  }, [id]);

  if (!service) {
    return (
      <div className="flex justify-center w-full p-6">
        <Loader />
      </div>
    );
  }

  switch (service.id) {
    case 0:
      return <BodyMeasurementService />;
    case 1:
      return <AutoTaggingService />;
    case 2:
      return <VisualClothesService />;
    default:
      return <div>Invalid service</div>;
  }
};

export default FreeTrialDetails;

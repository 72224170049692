import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { getWalletTransfersHistory, getWalletSummary, getPaymentsList } from "../../utilities/api/walletService";
import FinanceSummaryChart from "./FinanceSummaryChart";
import FinanceSummaryInfo from "./FinanceSummaryInfo";
import PaymentsTable from "./PaymentsTable";
import { enqueueSnackbar } from "notistack";
import Loader from "../loading/Loader";
import { DashboardContext } from "../../contexts/DashboardContext";

const Payment: React.FC = () => {
  const { dashboardMap, loading } = useContext(DashboardContext);
  const { accessToken } = useContext(AuthContext);
  const [transfersHistory, setTransfersHistory] = useState<any>({ chart: [] });
  const [walletSummary, setWalletSummary] = useState<any>({
    deposits: "0",
    withdraws: "0",
    balance: "0",
  });
  const [paymentsList, setPaymentsList] = useState<any>({ data: [], current_page: 1, last_page: 1 });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (accessToken) {
      fetchData();
    }
  }, [accessToken, currentPage]);

  const fetchData = async () => {
    if (!accessToken) {
      enqueueSnackbar("Authentication failed. Please log in again.", { variant: "error" });
      return;
    }
    setIsLoading(true);
    try {
      const [history, summary, payments] = await Promise.all([getWalletTransfersHistory(accessToken), getWalletSummary(accessToken), getPaymentsList(accessToken, currentPage)]);
      setTransfersHistory(history.data || { chart: [] });
      setWalletSummary(
        summary.data || {
          deposits: "0",
          withdraws: "0",
          balance: "0",
        }
      );
      setPaymentsList(payments.data || { data: [], current_page: 1, last_page: 1 });
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar("Unable to retrieve payment data. Please try again.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-6">
        <h2 className="font-bold text-closed-shutter-300">Payment</h2>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center p-6">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-6 p-6">
          <div className="flex justify-between gap-4">
            <FinanceSummaryChart data={transfersHistory} userCreatedAt={dashboardMap?.user?.created_at || Date.now() / 1000} />
            <FinanceSummaryInfo
              data={{
                payment: walletSummary.deposits,
                pending: walletSummary.withdraws,
                balance: walletSummary.balance,
              }}
            />
          </div>
          <PaymentsTable data={paymentsList} onPageChange={handlePageChange} />
        </div>
      )}
    </div>
  );
};

export default Payment;

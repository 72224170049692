import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { formatDate } from "../../utilities/helper/dateUtils";

interface FinanceSummaryInfoProps {
  data: {
    payment: string | number;
    pending: string | number;
    balance: string | number;
  };
}

const FinanceSummaryInfo: React.FC<FinanceSummaryInfoProps> = ({ data }) => {
  const chartRefBottom = useRef<HTMLCanvasElement>(null);
  const chartRefTop = useRef<HTMLCanvasElement>(null);
  const payment = typeof data.payment === "string" ? parseFloat(data.payment) : data.payment || 0;
  const pending = typeof data.pending === "string" ? parseFloat(data.pending) : data.pending || 0;
  const balance = typeof data.balance === "string" ? parseFloat(data.balance) : data.balance || 0;

  useEffect(() => {
    if (chartRefBottom.current && chartRefTop.current) {
      const ctxBottom = chartRefBottom.current.getContext("2d");
      const ctxTop = chartRefTop.current.getContext("2d");

      if (ctxBottom && ctxTop) {
        // Create gradients
        const paymentGradient = ctxBottom.createLinearGradient(0, 0, 200, 0);
        paymentGradient.addColorStop(0.2359, "#474BCF");
        paymentGradient.addColorStop(0.7167, "#393CA6");

        const pendingGradient = ctxBottom.createLinearGradient(0, 0, 200, 0);
        pendingGradient.addColorStop(0.2229, "#191A48");
        pendingGradient.addColorStop(0.7023, "#2B2D7C");

        new Chart(ctxBottom, {
          type: "doughnut",
          data: {
            datasets: [
              {
                data: [payment || 0.01, pending || 0.01],
                backgroundColor: [paymentGradient, pendingGradient],
                borderWidth: 0,
              },
            ],
          },
          options: {
            cutout: "70%",
            rotation: -90,
            circumference: 180,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: { enabled: false },
            },
          },
        });

        new Chart(ctxTop, {
          type: "doughnut",
          data: {
            datasets: [
              {
                data: [balance || 0.01, payment + pending - balance || 0.01],
                backgroundColor: ["#E3E4F8", "rgba(0,0,0,0)"],
                borderWidth: 0,
              },
            ],
          },
          options: {
            cutout: "70%",
            rotation: -90,
            circumference: 180,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: { enabled: false },
            },
          },
        });
      }
    }
  }, [payment, pending, balance]);

  return (
    <div className="flex flex-col gap-10 justify-between p-4 border rounded-md border-gorgonzola-blue-50 w-[23.75rem]">
      <div className="flex items-center justify-between w-full">
        <h3 className="text-base font-bold text-closed-shutter-300">Finance Summary</h3>
        <span className="text-xs capitalize text-closed-shutter-200">Up To Date: {formatDate(new Date().toLocaleDateString())}</span>
      </div>

      <div className="flex justify-between gap-10 *:relative *:flex *:items-center *:gap-3 *:min-h-[3.5rem]">
        <div>
          <span className="w-1 h-full rounded bg-gorgonzola-blue-300" />
          <div className="flex flex-col gap-1">
            <span className="text-xs text-closed-shutter-300/70">Deposits</span>
            <span className="text-base font-bold text-closed-shutter-300">${payment.toFixed(2)}</span>
          </div>
        </div>
        <div>
          <span className="w-1 h-full rounded bg-gorgonzola-blue-900" />
          <div className="flex flex-col gap-1">
            <span className="text-xs text-closed-shutter-300/70">Withdraws</span>
            <span className="text-base font-bold text-closed-shutter-300">${pending.toFixed(2)}</span>
          </div>
        </div>
        <div>
          <span className="w-1 h-full rounded bg-gorgonzola-blue-200" />
          <div className="flex flex-col gap-1">
            <span className="text-xs text-closed-shutter-300/70">Balance</span>
            <span className="text-base font-bold text-closed-shutter-300">${balance.toFixed(2)}</span>
          </div>
        </div>
      </div>

      <div className="w-full h-[8rem] relative">
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[15.875rem] h-full z-20">
          <canvas ref={chartRefBottom}></canvas>
        </div>
        <div className="absolute bottom-0 left-0 w-full h-[calc(100%+0.875rem)] z-10">
          <canvas ref={chartRefTop}></canvas>
        </div>
      </div>
    </div>
  );
};

export default FinanceSummaryInfo;

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { SidebarContext } from "../../contexts/SidebarContext";
import styles from "../../styles/button/SidebarButton.module.css";

interface CustomButtonProps {
  to?: string;
  active?: boolean;
  type: string;
  title: string;
  svg: React.ReactElement;
  isLogout?: boolean;
  onClick?: () => void;
}

interface SidebarButtonProps {
  to?: string;
  active?: boolean;
  type: string;
  title: string;
  svg: React.ReactElement;
  isLogout?: boolean;
  onClick?: () => void;
}

const MappedTypes: { [key: string]: string } = {
  primary: `${styles.base} ${styles.primary}`,
  logout: `${styles.base} ${styles.logout}`,
  upgrade: `${styles.base} ${styles.upgrade}`,
  upgrade_active: styles.upgrade_active,
  active: styles.active,
};

const SidebarButton: React.FC<SidebarButtonProps> = ({ to, type, title, svg, isLogout, active = false, onClick }) => {
  if (to) {
    return (
      <Link to={to}>
        <SidebarMainButton to={to} type={type} title={title} svg={svg} active={active} />
      </Link>
    );
  }

  return <SidebarMainButton to={to} type={type} title={title} svg={svg} isLogout={isLogout} active={active} onClick={onClick} />;
};

const SidebarMainButton: React.FC<CustomButtonProps> = ({ type, title, svg, isLogout, active, onClick }) => {
  const { isLogoOpen } = useContext(SidebarContext)!;
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      onClick={onClick}
      className={`${MappedTypes[type]} ${active ? (type === "upgrade" ? MappedTypes["upgrade_active"] : MappedTypes["active"]) : ""}`}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <motion.div
        className="relative w-fit"
        initial={{ x: 0 }}
        animate={
          isLogoOpen
            ? {
                scale: isHover ? 1.5 : 1,
              }
            : {
                x: isHover ? 10 : 0,
              }
        }
        transition={{ duration: 0.6 }}
      >
        <span>{React.cloneElement(svg, { variant: active ? "Bold" : "Broken", className: "size-6" })}</span>

        <AnimatePresence>
          {!isLogoOpen ? (
            <motion.span
              key={"title"}
              className="absolute px-4 text-xs top-1/2 left-full"
              initial={{ y: "-50%", opacity: 0 }}
              animate={{
                y: "-50%",
                opacity: 1,
              }}
              exit={{ y: "-50%", opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              {title}
            </motion.span>
          ) : null}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default SidebarButton;

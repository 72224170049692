import { ShakuPlan } from "../../utilities/types/plan/types";
import MainButton from "../button/MainButton";

interface ShakuPlansItemProps {
  plan: ShakuPlan;
  onClick: () => void;
}

const ShakuPlansItem: React.FC<ShakuPlansItemProps> = ({ plan, onClick }) => {
  return (
    <div className={`flex flex-col w-full ${!plan.mostPopulate ? "mt-7" : ""}`}>
      {plan.mostPopulate ? (
        <div className="w-full flex justify-center gap-1 p-1.5 bg-gorgonzola-blue-300 rounded-t-lg">
          <span className="text-xs font-semibold text-white">Most Popular</span>
          <img src="/assets/image/plan/sparkles.svg" alt="Most Popular" className="object-contain size-4" />
        </div>
      ) : null}

      <div
        className={`flex flex-col gap-10 w-full min-h-[33.75rem] pt-6 px-4 pb-4 bg-white transition-colors duration-600 hover:border-gorgonzola-blue-300 ${
          plan.mostPopulate ? "rounded-lg rounded-t-none border-2 border-gorgonzola-blue-300" : "border rounded-lg"
        }`}
      >
        <div className="flex flex-col gap-8">
          <div>
            <h2 className="text-lg font-bold">{plan.title}</h2>
          </div>
          <div>
            <span className="text-xl font-bold">${plan.price}</span>
            <span className="text-xs">/month</span>
          </div>
        </div>

        <MainButton type={plan.price === 0 ? "secondary" : "primary"} customClass="justify-center" onClick={onClick}>
          {plan.buttonText}
        </MainButton>

        <div className="flex flex-col gap-4">
          <span className="text-xs">{plan.featuresTitle}</span>
          {plan.features.map((feature, i) => (
            <div key={i} className="flex gap-4">
              <div className="size-6">
                <img src="/assets/image/plan/checkmark.svg" alt="Plan Feature Checkmark" className="object-contain w-full h-full" />
              </div>

              <span className="overflow-hidden text-xs whitespace-nowrap text-ellipsis">{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShakuPlansItem;

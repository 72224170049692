import instance from "./axiosInstance";

interface BusinessInfo {
  business_email?: string;
  company?: string;
  legal?: boolean;
  tax_number?: string;
  city?: string;
  country?: string;
  postal_code?: string;
  address?: string;
}

interface AddBusinessResponse {
  ok: boolean;
  message: string;
  data: any;
  status_code: number;
}

export const addBusiness = async (accessToken: string, businessInfo: BusinessInfo): Promise<AddBusinessResponse> => {
  try {
    const response = await instance.post<AddBusinessResponse>(
      "/api/v1/providers/addBusiness",
      { business_info: businessInfo },
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding business:", error);
    throw error;
  }
};

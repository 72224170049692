import React, { useState } from "react";
import { Eye } from "iconsax-react";
import { TableColumn } from "../../utilities/types/support/types";
import Table from "../table/Table";
import MainButton from "../button/MainButton";
import StatusBadge from "../table/StatusBadge";
import { ServiceHistoryItem } from "../../utilities/api/serviceHistoryService";
import TablePagination from "../table/TablePagination";
import ServiceDetailsModal from "./ServiceDetailsModal";
import { formatDate } from "../../utilities/helper/dateUtils";

interface ServiceHistoryTableProps {
  serviceHistory: ServiceHistoryItem[];
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

const ServiceHistoryTable: React.FC<ServiceHistoryTableProps> = ({ serviceHistory, currentPage, totalPages, onPageChange, hasPreviousPage, hasNextPage }) => {
  const [selectedService, setSelectedService] = useState<ServiceHistoryItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const serviceHistoryColumns: TableColumn<ServiceHistoryItem>[] = [
    {
      key: "service_name",
      header: "Services",
      render: (value: string) => <span>{value}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value: string) => {
        let isSuccess: boolean | null = null;
        if (value.toLowerCase() === "success") {
          isSuccess = true;
        } else if (value.toLowerCase() === "error") {
          isSuccess = false;
        }
        return <StatusBadge statusText={value} isSuccess={isSuccess} />;
      },
    },
    {
      key: "created_at",
      header: "Date",
      render: (value: number) => formatDate(new Date(value * 1000).toLocaleDateString()),
    },
    {
      key: "created_at",
      header: "Time",
      render: (value: number) => new Date(value * 1000).toLocaleTimeString(),
    },
    {
      key: "action",
      header: "Detail",
      render: (_, item: ServiceHistoryItem) => (
        <MainButton type="ghostOutlined" onClick={() => handleViewDetails(item)}>
          <Eye variant="Broken" className="size-6" />
          See
        </MainButton>
      ),
    },
  ];

  const handleViewDetails = (item: ServiceHistoryItem) => {
    setSelectedService(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };
  console.log(serviceHistory);
  return serviceHistory.length > 0 ? (
    <>
      <Table data={serviceHistory} columns={serviceHistoryColumns} />
      <TablePagination currentPage={currentPage} totalPages={totalPages} hasPreviousPage={hasPreviousPage} hasNextPage={hasNextPage} onPageChange={onPageChange} />
      {selectedService ? <ServiceDetailsModal isOpen={isModalOpen} onClose={handleCloseModal} serviceData={selectedService} serviceName={selectedService.service_name} /> : null}
    </>
  ) : (
    <div className="p-6 text-center">
      <p className="text-closed-shutter-300">No service usage history available</p>
      <p className="mt-2 text-xs text-closed-shutter-200">Your service usage history will appear here once you start using our services. Explore our available services to get started.</p>
    </div>
  );
};

export default ServiceHistoryTable;

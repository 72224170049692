import React, { useState, useEffect, forwardRef } from "react";
import { FlagImage, usePhoneInput, ParsedCountry } from "react-international-phone";
import { ArrowDown2 } from "iconsax-react";
import "react-international-phone/style.css";
import styles from "../../styles/text-field/text-field.module.css";
import CustomCountrySelectorDropdown from "./CountrySelectorDropdown";

interface PhoneFieldProps {
  label: string;
  placeholder: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  name?: string;
  customClass?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

const PhoneField = forwardRef<HTMLInputElement, PhoneFieldProps>(
  ({ label, placeholder, value, onChange, onKeyDown, onClick, disabled = false, error = false, success = false, name = "", customClass = "", required = false }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const { country, setCountry, inputRef } = usePhoneInput({
      defaultCountry: "ir",
      value: value,
      onChange: ({ phone, inputValue, country }) => {
        onChange({ target: { value: phone } } as React.ChangeEvent<HTMLInputElement>);
      },
    });

    useEffect(() => {
      return () => setIsFocused(false);
    }, []);

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleDropdownClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setShowDropdown(!showDropdown);
    };

    const handleCountrySelect = (selectedCountry: ParsedCountry) => {
      setCountry(selectedCountry.iso2);
      setShowDropdown(false);
    };

    const getInputClasses = () => {
      let multiClass = `${styles.base} `;
      if (isFocused) multiClass += ` ${styles.focused}`;
      if (error) multiClass += ` ${styles.error}`;
      // else if (success) multiClass += ` ${styles.success}`;
      if (customClass) multiClass += ` ${customClass}`;
      return multiClass;
    };

    return (
      <div className="relative z-10 flex flex-col gap-1">
        <label htmlFor={name} className={styles.label}>
          <span className={styles.labelText}>{label}</span>

          {required && <span className={styles.labelAsterisk}>*</span>}
        </label>

        <div className={`relative flex items-center gap-2 ${getInputClasses()}`}>
          <div className="flex items-center gap-2 text-xs cursor-pointer w-fit" onClick={handleDropdownClick}>
            <FlagImage iso2={country.iso2} size="16px" />
            <span className="">{country.iso2.toUpperCase()}</span>
            <ArrowDown2 className="size-6" />
          </div>
          <input
            id={name}
            type="tel"
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={onKeyDown}
            onClick={onClick}
            disabled={disabled}
            maxLength={15}
            className="w-full h-full bg-transparent focus:outline-none"
            ref={ref}
          />
          {showDropdown ? (
            <CustomCountrySelectorDropdown
              selectedCountry={country.iso2}
              onSelect={handleCountrySelect}
              show={showDropdown}
              onClose={() => setShowDropdown(false)}
              className="country-selector-dropdown"
              listItemClassName="react-international-phone-country-selector-dropdown__list-item"
              listItemFlagClassName="react-international-phone-country-selector-dropdown__list-item-flag"
            />
          ) : null}
        </div>
      </div>
    );
  }
);

PhoneField.displayName = "PhoneField";

export default PhoneField;

const TourGuideStep01 = () => {
  return (
    <div className="flex flex-col items-center w-full gap-4">
      <img src="/assets/image/tour-guide/TourGuideStep01.gif" alt="Tour Guide Step gif" className="w-[13.75rem] min-w-[13.75rem]" />
      <div className="space-y-6 text-center ">
        <h2 className="font-bold text-closed-shutter-300">Exclusive Free Plan Just for You!</h2>
        <p className="text-xs text-closed-shutter-300/70">
          We are excited to offer you an exclusive free plan! With this plan, you can make up to 198 requests for free. This is our gift to you, so you can explore and experience the full potential of
          our AI-powered solutions for your fashion business. We hope you enjoy this opportunity and find our services valuable. Let's get started today!
        </p>
      </div>
    </div>
  );
};

export default TourGuideStep01;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { logOutUser } from "../../utilities/api/logOutService";
import LogoutModal from "./LogoutModal";

export const useLogoutHandler = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken } = useContext(AuthContext);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [loggingOutLoader, setLoggingOutLoader] = useState(false);

  const handleLogout = async () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = async () => {
    try {
      setLoggingOutLoader(true);
      const logoutResponse = await logOutUser(accessToken!);

      if (logoutResponse.ok) {
        localStorage.removeItem("userInfo");
        setAccessToken(null);
        navigate("/auth/login");
      } else {
        alert(logoutResponse.message);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setShowLogoutModal(false);
      setLoggingOutLoader(false);
    }
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
  };

  return {
    handleLogout,
    showLogoutModal,
    LogoutModalComponent: <LogoutModal onConfirm={confirmLogout} onCancel={cancelLogout} isShowLogoutModal={showLogoutModal} loggingOutLoader={loggingOutLoader} />,
  };
};

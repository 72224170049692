import { createContext, useState, FC, ReactNode, useContext, useEffect, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { getTicketList, getTicketOptions } from "../utilities/api/ticketService";
import { Ticket, TicketOptions } from "../utilities/types/tickets/types";

interface SupportContextValue {
  tickets: Ticket[];
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  ticketOptions: TicketOptions;
  loading: boolean;
  fetchTickets: (page?: number) => Promise<void>;
  fetchTicketOptions: () => Promise<void>;
}

export const SupportContext = createContext<SupportContextValue>({
  tickets: [],
  totalPages: 1,
  hasPreviousPage: false,
  hasNextPage: true,
  ticketOptions: { priority_parts: [], section_parts: [] },
  loading: false,
  fetchTickets: async () => {},
  fetchTicketOptions: async () => {},
});

export const SupportProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [ticketOptions, setTicketOptions] = useState<TicketOptions>({ priority_parts: [], section_parts: [] });
  const [loading, setLoading] = useState(false);

  const fetchTickets = useCallback(
    async (page: number = 1) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getTicketList(accessToken, page);
        setTickets(response.data.data);
        setTotalPages(response.data.last_page);
        setHasPreviousPage(response.data.current_page > 1);
        setHasNextPage(response.data.current_page < response.data.last_page);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [accessToken]
  );

  const fetchTicketOptions = useCallback(async () => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const response = await getTicketOptions(accessToken);
      if (response.ok) {
        setTicketOptions(response.data);
      } else {
        console.error("Failed to fetch ticket options:", response.message);
      }
    } catch (err) {
      console.error("Error fetching ticket options:", err);
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchTicketOptions();
  }, [fetchTicketOptions]);

  return (
    <SupportContext.Provider
      value={{
        tickets,
        ticketOptions,
        loading,
        fetchTickets,
        fetchTicketOptions,
        totalPages,
        hasPreviousPage,
        hasNextPage,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};

export const useSupport = () => {
  const context = useContext(SupportContext);
  if (!context) {
    throw new Error("useSupport must be used within a SupportProvider");
  }
  return context;
};

import { createContext, useState, FC, ReactNode, useContext, useEffect } from "react";
import { authenticateWithGoogle } from "../utilities/api/authService";

interface AuthContextValue {
  accessToken: string | null;
  isAuthenticated: boolean;
  setAccessToken: (token: string | null) => void;
  setIsAuthenticated: (value: boolean) => void;
  googleSignIn: (token: string) => Promise<boolean>;
}

export const AuthContext = createContext<AuthContextValue>({
  accessToken: null,
  isAuthenticated: false,
  setAccessToken: () => {},
  setIsAuthenticated: () => {},
  googleSignIn: async () => false,
});

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const storedUserInfo = localStorage.getItem("userInfo");

  const [accessToken, setAccessToken] = useState<string | null>(storedUserInfo && JSON.parse(storedUserInfo).token ? JSON.parse(storedUserInfo).token : null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!(storedUserInfo && JSON.parse(storedUserInfo).token));

  useEffect(() => {
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      setAccessToken(userInfo.token);
      setIsAuthenticated(true);
    } else {
      setAccessToken(null);
      setIsAuthenticated(false);
    }
  }, [storedUserInfo]);

  const googleSignIn = async (token: string) => {
    try {
      const response = await authenticateWithGoogle(token);
      if (response.ok) {
        const userInfo = {
          user: "UserToken",
          token: response.data.access_token,
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        setAccessToken(response.data.access_token);
        setIsAuthenticated(true);
        return true;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      return false;
    }
  };

  const contextValue: AuthContextValue = {
    accessToken,
    setAccessToken,
    isAuthenticated,
    setIsAuthenticated,
    googleSignIn,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

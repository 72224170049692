export const useTimestamp = () => {
  const getTimestamp = (date: Date, isEndOfDay: boolean = false): number => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    if (isEndOfDay) {
      d.setDate(d.getDate() + 1);
    }
    return Math.floor(d.getTime() / 1000);
  };

  return { getTimestamp };
};

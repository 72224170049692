import React from "react";

interface StatusBadgeProps {
  statusText: string;
  isSuccess?: boolean | null;
  customClass?: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ statusText, isSuccess, customClass }) => {
  const getStatusColor = () => {
    if (isSuccess === true) {
      return "bg-shindig-100 text-shindig-300";
    } else if (isSuccess === false) {
      return "bg-english-vermillion-100 text-english-vermillion-300";
    } else {
      return "bg-closed-shutter-100 text-closed-shutter-300";
    }
  };

  const getDotColor = () => {
    if (isSuccess === true) {
      return "bg-shindig-300";
    } else if (isSuccess === false) {
      return "bg-english-vermillion-300";
    } else {
      return "bg-closed-shutter-300/70";
    }
  };

  return (
    <div className={`relative flex items-center w-fit px-2 py-1 gap-2 text-xs capitalize rounded-md ${getStatusColor()} ${customClass}`}>
      <span className={`min-w-3 size-3 rounded-full ${getDotColor()}`} />
      <span className="line-clamp-1">{statusText}</span>
    </div>
  );
};

export default StatusBadge;

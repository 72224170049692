import React from "react";
import { useNavigate } from "react-router-dom";
import ReusableModal from "../modal/ReusableModal";
import MainButton from "../button/MainButton";
import { CardPos } from "iconsax-react";

interface PaymentStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: string;
}

const PaymentStatusModal: React.FC<PaymentStatusModalProps> = ({ isOpen, onClose, status }) => {
  const navigate = useNavigate();

  const getModalContent = () => {
    switch (status) {
      case "success":
        return {
          title: "Payment Successful",
          description: "Your payment has been processed successfully.",
          image: "/assets/image/payment/payment-success.svg",
        };
      case "failed":
        return {
          title: "Payment Failed",
          description: "We couldn't process your payment. Please try again.",
          image: "/assets/image/payment/payment-failed.svg",
        };
      default:
        return {
          title: "Payment Status",
          description: "We've received an update on your payment.",
          image: "/assets/image/payment/payment-status.svg",
        };
    }
  };

  const { title, description, image } = getModalContent();

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-6 w-fit max-w-[22.375rem]">
        <img src={image} alt="Payment Status" className="min-h-[11.625rem]" />
        <div className="text-center">
          <h2 className="text-closed-shutter-300">{title}</h2>
          <p className="text-xs text-closed-shutter-300/70">{description}</p>
        </div>
        <div className="flex justify-center w-full">
          <MainButton type="primary" onClick={() => navigate("/dashboard/payment")}>
            Go to Payments
            <CardPos className="size-6" variant="Broken" />
          </MainButton>
        </div>
      </div>
    </ReusableModal>
  );
};

export default PaymentStatusModal;

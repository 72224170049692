import { DashboardResponse } from "../types/mianDashboard/types";
import instance from "./axiosInstance";

export const getDashboardMap = async (accessToken: string): Promise<DashboardResponse> => {
  try {
    const response = await instance.get("/api/v1/dashboard/map", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard map:", error);
    throw error;
  }
};

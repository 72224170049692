interface StatisticItemProps {
  color: string;
  label: string;
  value: number;
}

const StatisticItem: React.FC<StatisticItemProps> = ({ color, label, value }) => (
  <div className="flex flex-col gap-3">
    <div className="flex items-center gap-1">
      <div className={`w-3 h-3 rounded-full`} style={{ backgroundColor: color }} />
      <span className="text-xs text-closed-shutter-300/70">{label}</span>
    </div>
    <span className="font-bold capitalize text-closed-shutter-300">{value}</span>
  </div>
);

export default StatisticItem;

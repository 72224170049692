import React, { useContext } from "react";
import { DashboardContext } from "../contexts/DashboardContext";
import { HierarchySquare3, People, Signpost } from "iconsax-react";
import MainButton from "./button/MainButton";
import ProjectDeliveriesChart from "./project-deliveries-chart/ProjectDeliveriesChart";
import UserStatistics from "./user-statistics/UserStatistics";
import BalanceOverview from "./main-dashboard/BalanceOverview";
import PlanOverview from "./main-dashboard/PlanOverview";
import { DashboardMapData } from "../utilities/types/mianDashboard/types";
import Loader from "./loading/Loader";
import { Link } from "react-router-dom";

const MainDashboard: React.FC = () => {
  const { dashboardMap, loading } = useContext(DashboardContext);

  if (loading) {
    return (
      <div className="flex justify-center w-full p-6">
        <Loader />
      </div>
    );
  }

  if (!dashboardMap) {
    return <p className="mb-4 font-bold text-gray-700">No data available</p>;
  }

  const balance = Number(dashboardMap.funds?.wallet?.balance) || 0;
  const transactions = dashboardMap.balance?.transactions || [];

  return (
    <div className="flex flex-col items-center gap-4 p-6">
      <div className="flex justify-between w-full gap-4 h-[23.25rem] overflow-hidden">
        <div className="relative flex justify-end flex-grow overflow-hidden rounded-md min-h-max bg-gorgonzola-blue-900">
          <img src="/assets/image/main-dashboard/welcome-bg.png" alt="Welcome Background" className="object-cover" />
          <div className="absolute inset-0 flex flex-col items-start justify-center h-full gap-20 left-9">
            <div className="flex flex-col gap-6 w-[49%]">
              <h1 className="text-lg font-bold text-gorgonzola-blue-50">
                Welcome{" "}
                {dashboardMap.user.first_name || dashboardMap.user.last_name ? (
                  <>
                    {dashboardMap.user.first_name} {dashboardMap.user.last_name}
                  </>
                ) : (
                  "Dear User"
                )}
                ! To Shaku
              </h1>
              <p className="text-xs text-gorgonzola-blue-100">54% more users have used your service than last month, That is a big thing</p>
            </div>
            <div className="flex gap-4">
              <Link to={"/dashboard/tutorial"}>
                <MainButton type="secondary">
                  <Signpost className="size-6" variant="Broken" />
                  <span className="text-xs">Tutorial</span>
                </MainButton>
              </Link>

              <Link to={"/dashboard/free-trial"}>
                <MainButton type="primary">
                  <HierarchySquare3 className="size-6" variant="Broken" />
                  <span className="text-xs">Start Your Free Trial</span>
                </MainButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 min-h-max w-fit *:w-[18.875rem]">
          <BalanceOverview balance={balance} transactions={transactions} />
          <PlanOverview activedOrders={dashboardMap.orders?.actived_orders || []} />
        </div>
      </div>

      <div className="flex justify-between w-full gap-4">
        <ProjectDeliveriesChart userCreatedAt={dashboardMap.user.created_at} />

        <UserStatistics userCreatedAt={dashboardMap.user.created_at} />
      </div>
    </div>
  );
};

export default MainDashboard;

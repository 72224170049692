import React from "react";
import { ClothesDetectionResult, ClothingItem } from "../../utilities/types/free-trial/auto-tagging-service/types";

interface ClothesRecognitionResultProps {
  clothesData: ClothesDetectionResult;
}

const ClothesRecognitionResult: React.FC<ClothesRecognitionResultProps> = ({ clothesData }) => {
  const renderTag = (text: string) => (
    <div className="flex items-center gap-2 p-2 capitalize rounded-md bg-gradient-to-r from-[#FAFAED] from-10%  via-[#E3E4F8] via-30% to-[#EDEDFA] to-90% text-closed-shutter-300">
      <img src="/assets/image/free-trial/auto-tagging/sparkles.svg" alt="sparkles" className="size-6" />
      <span>{text}</span>
    </div>
  );

  const renderColorTag = (color: any, index: number) => {
    const processColor = (colorValue: any) => {
      if (typeof colorValue === "string") {
        const match = colorValue.match(/\[(\d+)\s+(\d+)\s+(\d+)\]/);
        if (match) {
          return { r: parseInt(match[1]), g: parseInt(match[2]), b: parseInt(match[3]) };
        }
      } else if (Array.isArray(colorValue) && colorValue.length > 0 && typeof colorValue[0] === "string") {
        const match = colorValue[0].match(/\[(\d+)\s+(\d+)\s+(\d+)\]/);
        if (match) {
          return { r: parseInt(match[1]), g: parseInt(match[2]), b: parseInt(match[3]) };
        }
      }
      return null;
    };

    const colorObj = processColor(color);

    if (colorObj) {
      return (
        <div key={index} className="flex items-center gap-2 p-2 capitalize rounded-md bg-gradient-to-r from-[#FAFAED] from-10%  via-[#E3E4F8] via-30% to-[#EDEDFA] to-90% text-closed-shutter-300">
          <img src="/assets/image/free-trial/auto-tagging/sparkles.svg" alt="sparkles" className="size-6" />
          <span>Color</span>
          <div className="size-6 rounded-full" style={{ backgroundColor: `rgb(${colorObj.r}, ${colorObj.g}, ${colorObj.b})` }} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-4">
      {clothesData.Clothes.map((item: ClothingItem, index: number) => (
        <div key={index} className="flex flex-wrap gap-2">
          {renderTag(item.ClotheType)}
          {item.Design && renderTag(item.Design)}
          {item.Sleeve && renderTag(item.Sleeve)}
          {item.Neckline && renderTag(item.Neckline)}
          {item.Crop && renderTag(item.Crop)}
          {item.Colors && item.Colors.map((color, colorIndex) => renderColorTag(color, colorIndex))}
        </div>
      ))}
    </div>
  );
};

export default ClothesRecognitionResult;

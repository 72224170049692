import React, { useState, useRef, useEffect } from "react";

interface OTPInputProps {
  length: number;
  onChange: (otp: string) => void;
  error?: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onChange, error = false }) => {
  const [otp, setOtp] = useState<string[]>(new Array(length).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (isNaN(Number(value))) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    const otpString = newOtp.join("");
    onChange(otpString);

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    switch (e.key) {
      case "ArrowLeft":
        e.preventDefault();
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
        break;
      case "ArrowRight":
        e.preventDefault();
        if (index < length - 1) {
          inputRefs.current[index + 1]?.focus();
        }
        break;
      case "Backspace":
        if (!otp[index] && index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
        break;
    }
  };

  return (
    <div className="flex justify-between gap-3">
      {otp.map((digit, index) => (
        <input
          key={index}
          ref={(ref) => (inputRefs.current[index] = ref)}
          type="text"
          inputMode="numeric"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          aria-label={`Digit ${index + 1} of OTP`}
          className={`size-[3.125rem] p-3 text-center text-base border border-gorgonzola-blue-100 rounded-md text-closed-shutter-300  transition duration-600 focus:outline-none focus:ring-1 focus:ring-gorgonzola-blue-300 focus:text-gorgonzola-blue-300 
    ${error ? "ring-1 ring-english-vermillion-300 text-english-vermillion-300" : "border-gorgonzola-blue-100 text-closed-shutter-300"}
    `}
        />
      ))}
    </div>
  );
};

export default OTPInput;

import React, { useContext, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import MainButton from "../../button/MainButton";
import TextField from "../../fields/TextField";
import { AuthContext } from "../../../contexts/AuthContext";
import { changePassword } from "../../../utilities/api/passwordService";
import { enqueueSnackbar } from "notistack";
import PasswordChangeModal from "../../modal/PasswordChangeModal";

interface PasswordFormData {
  currentPassword: string;
  newPassword: string;
}

const Password: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<PasswordFormData>({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });

  const watchCurrentPassword = watch("currentPassword");
  const watchNewPassword = watch("newPassword");

  const isFormValid = watchCurrentPassword && watchNewPassword && Object.keys(errors).length === 0 && watchCurrentPassword !== watchNewPassword;

  const onSubmit: SubmitHandler<PasswordFormData> = async (data) => {
    if (!accessToken) {
      enqueueSnackbar("Authentication failed. Please log in again.", { variant: "error" });
      return;
    }

    if (data.currentPassword === data.newPassword) {
      enqueueSnackbar("New password must be different from your current password. Please choose a different password.", { variant: "error" });
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await changePassword(accessToken, {
        current_password: data.currentPassword,
        new_password: data.newPassword,
      });

      if (response.ok) {
        setCurrentPassword(data.currentPassword);
        setNewPassword(data.newPassword);
        setIsModalOpen(true);
        reset();
      } else {
        enqueueSnackbar(response.message || "Unable to change password. Please try again.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error changing password:", error);
      enqueueSnackbar("An error occurred during password change. Please try again later.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    reset();
  };

  const handleModalConfirm = () => {
    setIsModalOpen(false);
    reset();
  };

  return (
    <div className="flex flex-col gap-13 min-h-[40rem]">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="grid grid-cols-2 gap-12">
          <div className="flex flex-col gap-2">
            <Controller
              name="currentPassword"
              control={control}
              rules={{ required: "Current password is required" }}
              render={({ field }) => (
                <TextField
                  label="Current Password"
                  required
                  placeholder="Enter your current password"
                  type="password"
                  error={!!errors.currentPassword}
                  // success={!!watchCurrentPassword && !errors.currentPassword}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: "New password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])(?!.*\s).{8,}$/,
                  message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                },
                validate: (value) => value !== watchCurrentPassword || "New password must be different from your current password. Please choose a different password.",
              }}
              render={({ field }) => (
                <TextField
                  label="New Password"
                  placeholder="Enter your new password"
                  type="password"
                  required
                  showPasswordHint
                  error={!!errors.newPassword}
                  success={!!watchNewPassword && !errors.newPassword}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end w-full">
          <MainButton type="submit" isSubmit disabled={!isFormValid || isSubmitting}>
            {isSubmitting ? "Changing Password..." : "Change Password"}
          </MainButton>
        </div>
      </form>
      <PasswordChangeModal isOpen={isModalOpen} onClose={handleModalClose} onConfirm={handleModalConfirm} currentPassword={currentPassword} newPassword={newPassword} accessToken={accessToken || ""} />
    </div>
  );
};

export default Password;

import React, { useEffect, useState } from "react";
import { ParsedCountry, CountryData } from "react-international-phone";
import { FlagImage } from "react-international-phone";

interface CustomCountryListProps {
  countries: CountryData[];
  onSelect: (country: ParsedCountry) => void;
  selectedCountry: string;
}

const CustomCountryList: React.FC<CustomCountryListProps> = ({ countries, onSelect, selectedCountry }) => {
  const [filteredCountries, setFilteredCountries] = useState(countries);

  useEffect(() => {
    setFilteredCountries(countries);
  }, [countries]);

  return (
    <div className="pt-2">
      <ul className="max-h-[200px] overflow-y-auto">
        {filteredCountries.map((country) => (
          <li
            key={country[1]}
            className={`flex items-center justify-between p-2 cursor-pointer hover:bg-gray-100 ${selectedCountry === country[1] ? "bg-gray-200" : ""}`}
            onClick={() =>
              onSelect({
                name: country[0],
                iso2: country[1],
                dialCode: country[2],
                format: country[3],
                priority: country[4],
                areaCodes: country[5]?.length ? country[5] : undefined,
              })
            }
          >
            <div className="flex items-center">
              <FlagImage iso2={country[1]} />
              <span className="ml-2">{country[0]}</span>
            </div>
            <span className="text-gray-500">{country[2]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomCountryList;

import { CardPos, Crown1, Data2, HierarchySquare3, HomeHashtag, LogoutCurve, MessageQuestion, People, ProfileCircle, Signpost } from "iconsax-react";
import { SidebarItem } from "../../types/sidebar/types";

// Main Sidebar Items
export const MAIN_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Dashboard",
    href: "/dashboard",
    type: "primary",
    svg: <HomeHashtag />,
  },
  {
    id: 1,
    title: "Free Trial",
    href: "/dashboard/free-trial",
    type: "primary",
    svg: <HierarchySquare3 />,
  },
  {
    id: 2,
    title: "Application",
    href: "/dashboard/application",
    type: "primary",
    svg: <Data2 />,
  },
  {
    id: 3,
    title: "Plans",
    href: "/dashboard/plans",
    type: "primary",
    svg: <Crown1 />,
  },
  {
    id: 4,
    title: "Usage",
    href: "/dashboard/usage",
    type: "primary",
    svg: <People />,
  },
  {
    id: 5,
    title: "Payment",
    href: "/dashboard/payment",
    type: "primary",
    svg: <CardPos />,
  },
];

// Primary Sidebar Items
export const PRIMARY_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Profile",
    href: "/dashboard/profile",
    type: "primary",
    svg: <ProfileCircle />,
  },
  {
    id: 1,
    title: "Tutorial",
    href: "/dashboard/tutorial",
    type: "primary",
    svg: <Signpost />,
  },
  {
    id: 2,
    title: "Support",
    href: "/dashboard/support",
    type: "primary",
    svg: <MessageQuestion />,
  },
  {
    id: 3,
    title: "Log Out",
    logout: true,
    type: "logout",
    svg: <LogoutCurve />,
  },
];

// Upgrade Sidebar Items
export const UPGRADE_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Upgrade Plan",
    href: "/dashboard/plans",
    type: "upgrade",
    svg: <Crown1 />,
  },
];

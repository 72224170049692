import React from "react";
import MainButton from "../button/MainButton";
import { ArrowLeft, ArrowRight } from "iconsax-react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onPageChange: (newPage: number) => void;
}

const TablePagination: React.FC<PaginationProps> = ({ currentPage, totalPages, hasPreviousPage, hasNextPage, onPageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const showEllipsis = totalPages > 7;

    if (showEllipsis) {
      if (currentPage <= 4) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(<li key="ellipsis1">...</li>);
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(renderPageButton(i));
        }
      } else if (currentPage >= totalPages - 3) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(<li key="ellipsis2">...</li>);
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(renderPageButton(i));
        }
      } else {
        pageNumbers.push(renderPageButton(1));
        pageNumbers.push(<li key="ellipsis1">...</li>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(<li key="ellipsis2">...</li>);
        pageNumbers.push(renderPageButton(totalPages));
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPageButton(i));
      }
    }

    return pageNumbers;
  };

  const renderPageButton = (pageNumber: number) => (
    <li key={pageNumber} onClick={() => onPageChange(pageNumber)}>
      <MainButton type={currentPage === pageNumber ? "ghostOutlined" : "ghost"}>{pageNumber}</MainButton>
    </li>
  );

  return (
    <div className="flex items-center justify-between w-full gap-5 px-4 py-6">
      <MainButton type="ghostOutlined" disabled={!hasPreviousPage} onClick={() => onPageChange(currentPage - 1)}>
        <ArrowLeft className="size-6" variant="Broken" />
        <span>Previous</span>
      </MainButton>
      <ul className="flex space-x-2">{renderPageNumbers()}</ul>
      <MainButton type="ghostOutlined" disabled={!hasNextPage} onClick={() => onPageChange(currentPage + 1)}>
        <span>Next</span>
        <ArrowRight className="size-6" variant="Broken" />
      </MainButton>
    </div>
  );
};

export default TablePagination;

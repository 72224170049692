import React from "react";
import ReusableModal from "./ReusableModal";
import MainButton from "../button/MainButton";

interface LogoutModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isShowLogoutModal: boolean;
  loggingOutLoader: boolean;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ onConfirm, onCancel, isShowLogoutModal, loggingOutLoader }) => {
  return (
    <ReusableModal isOpen={isShowLogoutModal} onClose={onCancel} showCloseButton={false}>
      <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-6 w-fit max-w-[22.375rem]">
        {loggingOutLoader ? (
          <div className="flex flex-col items-center gap-6 bg-white rounded-xl w-fit">
            <div className="border-4 rounded-full size-16 border-english-vermillion-300 border-t-transparent animate-spin" />
          </div>
        ) : (
          <>
            <img src="/assets/image/logout/logout-Illustration.svg" alt="logout Illustration" className="min-h-[11.625rem]" />
            <div className="text-center">
              <h2 className="text-closed-shutter-300">Confirm Logout</h2>
              <p className="text-xs text-closed-shutter-300/70">Are you sure you want to exit the application?</p>
            </div>
            <div className="relative flex items-center justify-center space-x-4">
              <MainButton type="ghostOutlined" onClick={onCancel}>
                Cancel
              </MainButton>
              <MainButton type="error" onClick={onConfirm}>
                Log Out
              </MainButton>
            </div>
          </>
        )}
      </div>
    </ReusableModal>
  );
};

export default LogoutModal;

import { ArrowRight2 } from "iconsax-react";
import { Link, useLocation } from "react-router-dom";

interface BreadcrumbProps {
  isId?: boolean;
  customTag?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ isId = false, customTag }) => {
  const { pathname } = useLocation();
  const paths = pathname.split("/").filter(Boolean).slice(1);

  return (
    <div aria-label="Breadcrumb" className="flex flex-col gap-1 px-6 py-3 border-b border-gorgonzola-blue-100">
      <ol className="flex items-center text-xs">
        {paths.map((path, index) => {
          const currentPath = `/${paths.slice(0, index + 1).join("/")}`;
          const isLastPath = index === paths.length - 1;

          return (
            <li key={index} className="flex flex-col items-center capitalize">
              <div className="flex items-center">
                {isLastPath ? (
                  <span className="text-closed-shutter-300/70">
                    {isId && "#"}
                    {customTag ? customTag : <>{path.replace(/-/g, " ")}</>}
                  </span>
                ) : (
                  <Link to={`/dashboard${currentPath}`} className="text-closed-shutter-300/70 hover:text-closed-shutter-300/50">
                    {path.replace(/-/g, " ")}
                  </Link>
                )}
                {!isLastPath && <ArrowRight2 className="size-4 text-closed-shutter-300/70" />}
              </div>
            </li>
          );
        })}
      </ol>

      <span className="font-bold capitalize text-closed-shutter-300">
        {isId && "#"}
        {customTag ? customTag : <>{paths[paths.length - 1].replace(/-/g, " ")}</>}
      </span>
    </div>
  );
};

export default Breadcrumb;

import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import { AuthContext } from "./AuthContext";
import { getUserInfo } from "../utilities/api/globalInfoService";
import { enqueueSnackbar } from "notistack";
import { UserInfo } from "../utilities/types/user/types";
import TourGuideModal from "../components/tour-guide/TourGuideModal";
import { updateUserTourGuideStatus } from "../utilities/api/userService";

interface GlobalInfoContextType {
  userInfo: UserInfo | null;
  loading: boolean;
  refreshUserInfo: () => Promise<void>;
}

const GlobalInfoContext = createContext<GlobalInfoContextType | undefined>(undefined);

interface GlobalInfoProviderProps {
  children: ReactNode;
}

export const GlobalInfoProvider: React.FC<GlobalInfoProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [showTourGuide, setShowTourGuide] = useState(false);
  const { accessToken } = useContext(AuthContext);

  const fetchUserInfo = async () => {
    if (!accessToken) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await getUserInfo(accessToken);
      if (response.ok) {
        setUserInfo(response.data);
        if (!response.data.tour_guide_passed) {
          setShowTourGuide(true);
        }
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Failed to retrieve user information. Please try again.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, [accessToken]);

  const refreshUserInfo = async () => {
    await fetchUserInfo();
  };

  const handleCloseTourGuide = () => {
    setShowTourGuide(false);
    updateUserTourGuideStatus(accessToken!);
  };

  return (
    <GlobalInfoContext.Provider value={{ userInfo, loading, refreshUserInfo }}>
      {children}
      <TourGuideModal isOpen={showTourGuide} onClose={handleCloseTourGuide} />

      {/* for test tour: */}
      {/* <TourGuideModal isOpen={true} onClose={handleCloseTourGuide} /> */}
    </GlobalInfoContext.Provider>
  );
};

export const useGlobalInfo = () => {
  const context = useContext(GlobalInfoContext);
  if (!context) {
    throw new Error("useGlobalInfo must be used within a GlobalInfoProvider");
  }
  return context;
};

import React, { forwardRef, useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import Select, { StylesConfig, components } from "react-select";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import styles from "../../styles/text-field/text-field.module.css";

interface Option {
  value: string | boolean;
  label: string;
}

interface SelectFieldProps<TFieldValues extends FieldValues> {
  label: string;
  options: Option[];
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  required?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  error?: boolean;
  success?: boolean;
}

const SelectField = forwardRef<HTMLDivElement, SelectFieldProps<any>>((props, ref) => {
  const { label, options, name, control, placeholder = "Select...", isDisabled = false, error = false, success = false, required = false } = props;
  const [isOpen, setIsOpen] = useState(false);

  const getInputClasses = () => {
    let multiClass = `${styles.base} !p-0`;
    if (error) multiClass += ` ${styles.error}`;
    return multiClass;
  };

  const customStyles: StylesConfig<Option, false> = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F9F9FA",
      borderColor: "transparent",
      boxShadow: "transparent",
      "&:hover": {
        borderColor: "transparent",
      },
      padding: "0",
      cursor: "pointer",
      minWidth: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#EDECEE" : state.isFocused ? "#EDECEE" : "#F9F9FA",
      color: "#484554",
      padding: "0.75rem 1rem",
      borderRadius: "8px",
      cursor: "pointer",
      minWidth: "100%",
      "&:hover": {
        backgroundColor: "#EDECEE",
      },
      marginBottom: "0.5rem",
      "&:last-child": {
        marginBottom: "0",
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "1rem",
      boxShadow: "0px 4px 4px 0px #F6F6F6",
      borderRadius: "0.5rem",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0.5rem 0.75rem",
      backgroundColor: "#F9F9FA",
      width: "calc(100%+1.5rem)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        margin: "0.5rem 0",
        width: "0.75rem",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EDEDFA",
        borderRadius: "1rem",
      },
      "&::-webkit-scrollbar-thumb": {
        position: "relative",
        background: "#FFFFFF",
        border: "1px solid #E3E4F8",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#FFFFFF",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#484554",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#A3A2AA",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#484554",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <ArrowDown2 variant="Broken" className="size-5 text-closed-shutter-300" /> : <ArrowRight2 variant="Broken" className="size-5 text-closed-shutter-300" />}
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="flex flex-col w-full gap-1" ref={ref}>
      <label htmlFor={name} className="flex items-center gap-2 text-xs font-bold text-closed-shutter-300">
        {label}

        {required && <span className="text-english-vermillion-300">*</span>}
      </label>
      <div className={`relative w-full flex items-center gap-2 ${getInputClasses()}`}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              styles={customStyles}
              placeholder={placeholder}
              isDisabled={isDisabled}
              classNamePrefix="react-select"
              components={{
                DropdownIndicator,
              }}
              isSearchable={false}
              className="w-full"
              onMenuOpen={() => setIsOpen(true)}
              onMenuClose={() => setIsOpen(false)}
              value={field.value.value === `Select ${name}` ? null : field.value}
            />
          )}
        />
      </div>
    </div>
  );
});

SelectField.displayName = "SelectField";

export default SelectField;

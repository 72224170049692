import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAutoTaggingServiceContext } from "../../../contexts/AutoTaggingServiceContext";
import { ClothingItem } from "../../../utilities/types/free-trial/auto-tagging-service/types";
import ImageDisplay from "./AutoTaggingServiceImageDisplay";

const InteractiveAutoTaggingResult: React.FC = () => {
  const { autoTaggingResult, getAdjustedCoordinates, hoveredItem, setHoveredItem } = useAutoTaggingServiceContext();

  const [showAnalysisAnimation, setShowAnalysisAnimation] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => setShowAnalysisAnimation(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const getCenter = (region: ClothingItem["Region"]) => {
    const { X1, Y1, X2, Y2 } = getAdjustedCoordinates(region);
    return {
      x: (X1 + X2) / 2,
      y: (Y1 + Y2) / 2,
    };
  };

  const getInverseClipPath = (region: ClothingItem["Region"]) => {
    const { X1, Y1, X2, Y2 } = getAdjustedCoordinates(region);
    return `polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 0%,
        ${X1}% ${Y1}%,
        ${X1}% ${Y2}%,
        ${X2}% ${Y2}%,
        ${X2}% ${Y1}%,
        ${X1}% ${Y1}%
      )`;
  };

  const infiniteAnimation = {
    x: "-50%",
    y: "-50%",
    scale: [1, 1.2, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  return (
    <div ref={containerRef} className="relative w-fit">
      <ImageDisplay />

      <AnimatePresence>
        {showAnalysisAnimation ? (
          <motion.div className="absolute inset-0 grid place-content-center bg-black/50 z-50 *:size-20" initial="hidden" animate="visible" exit="hidden">
            <img src="/assets/image/free-trial/auto-tagging/analyzing.gif" alt="analyzing gif" />
          </motion.div>
        ) : null}
      </AnimatePresence>

      {autoTaggingResult?.Clothes.map((item, index) => {
        const center = getCenter(item.Region);
        const { X1, Y1, X2, Y2 } = getAdjustedCoordinates(item.Region);
        return (
          <div key={index}>
            {!hoveredItem ? (
              <motion.div
                className="absolute rounded-full opacity-50 bg-gorgonzola-blue-300"
                style={{
                  left: `${center.x}%`,
                  top: `${center.y}%`,
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                initial={{ x: "-50%", y: "-50%" }}
                animate={infiniteAnimation}
                whileHover={{ scale: 1.5 }}
                onMouseEnter={() => setHoveredItem(item)}
              />
            ) : null}
            {hoveredItem === item ? (
              <div
                className="absolute"
                style={{
                  left: `${X1}%`,
                  top: `${Y1}%`,
                  width: `${X2 - X1}%`,
                  height: `${Y2 - Y1}%`,
                  cursor: "pointer",
                }}
                onMouseLeave={() => setHoveredItem(null)}
              />
            ) : null}
          </div>
        );
      })}

      <AnimatePresence>
        {hoveredItem ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black bg-opacity-50"
            style={{
              clipPath: getInverseClipPath(hoveredItem.Region),
            }}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default InteractiveAutoTaggingResult;

import instance from "./axiosInstance";

const ticketUrl = "/api/v1/tickets";

export const getTicketOptions = async (accessToken: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${ticketUrl}/prepare`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTicketList = async (accessToken: string, page: number) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${ticketUrl}/list?page=${page}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTicket = async (accessToken: string, data: { title: string; message?: string; priority: string; section: string; file?: string }) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  instance.defaults.headers.common["Charset"] = `utf-8`;
  try {
    const response = await instance.post(ticketUrl, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (accessToken: string, data: { ticket_id: number; message?: string; file?: string }) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.post(`${ticketUrl}/messages`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChat = async (accessToken: string, params: { ticket_id: number; update_messages_status?: boolean }) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${ticketUrl}?ticket_id=${params.ticket_id.toString()}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import MainButton from "./MainButton";

interface GoogleButtonProps {
  onSuccess: (credentialResponse: any) => void;
  onError: () => void;
  onSubmiting: boolean;
  text: string;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({ onSuccess, onError, onSubmiting, text }) => {
  const login = useGoogleLogin({
    onSuccess,
    onError,
  });

  const handleGoogleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    login();
  };

  return (
    <MainButton type="secondary" onClick={handleGoogleClick} customClass="justify-center min-h-14">
      <img src="/assets/image/authenticate/google.svg" alt="google icon" className="size-6" />
      {onSubmiting ? "Waiting..." : text}
    </MainButton>
  );
};

export default GoogleButton;

import React from "react";
import { motion } from "framer-motion";

const Loader: React.FC = () => {
  const containerVariants = {
    animate: {
      rotate: 360,
      transition: {
        duration: 3,
        repeat: Infinity,
        ease: "linear",
      },
    },
  };

  const circleVariants = {
    animate: (i: number) => ({
      scale: [1, 1.2, 1],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        delay: i * 0.2,
        ease: "easeInOut",
      },
    }),
  };

  return (
    <motion.div className="relative grid place-content-center size-6" variants={containerVariants} animate="animate">
      {[...Array(4)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute rounded-full size-2 bg-gorgonzola-blue-300"
          style={{
            top: i === 0 || i === 1 ? 0 : "auto",
            bottom: i === 2 || i === 3 ? 0 : "auto",
            left: i === 0 || i === 3 ? 0 : "auto",
            right: i === 1 || i === 2 ? 0 : "auto",
          }}
          variants={circleVariants}
          animate="animate"
          custom={i}
        />
      ))}
    </motion.div>
  );
};

export default Loader;

import React from "react";
import MainButton from "../button/MainButton";

interface ErrorDisplayProps {
  error: string;
  onRetry: () => void;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error, onRetry }) => {
  return (
    <div className="flex flex-col items-center w-full gap-6">
      <span className="font-bold text-center capitalize text-english-vermillion-300">{error}</span>
      <MainButton onClick={onRetry}>Try Again</MainButton>
    </div>
  );
};

export default ErrorDisplay;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { SearchNormal1 } from "iconsax-react";
import { ParsedCountry, CountryData, defaultCountries } from "react-international-phone";
import CustomCountryList from "./CustomCountryList.";

interface CustomCountrySelectorDropdownProps {
  selectedCountry: string;
  onSelect: (country: ParsedCountry) => void;
  show: boolean;
  onClose: () => void;
  className?: string;
  listItemClassName?: string;
  listItemFlagClassName?: string;
}

const CustomCountrySelectorDropdown: React.FC<CustomCountrySelectorDropdownProps> = ({ selectedCountry, onSelect, show, onClose, className, listItemClassName, listItemFlagClassName }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState<CountryData[]>(defaultCountries);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownSearchRef = useRef<HTMLInputElement>(null);
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && dropdownSearchRef.current && !dropdownSearchRef.current.contains(event.target as Node)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, handleClickOutside]);

  useEffect(() => {
    if (!show) {
      setSearchQuery("");
      setFilteredCountries(defaultCountries);
    }
  }, [show]);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setFilteredCountries(filterCountries(defaultCountries, query));
  };
  const filterCountries = (countries: CountryData[], query: string) => {
    // return countries.filter((country) => country[1].toLowerCase().includes(query.toLowerCase()) || country[0].toLowerCase().includes(query.toLowerCase()));
    return countries.filter(
      (country) => country[0].toLowerCase().includes(query.toLowerCase()) || country[1].toLowerCase().includes(query.toLowerCase()) || country[2].toLowerCase().includes(query.toLowerCase())
    );
  };

  return (
    <div className="absolute left-0 w-full p-2 overflow-hidden bg-[#F9F9FA] border rounded-lg shadow-lg min-h-5 top-[calc(100%+0.5rem)]">
      <div ref={dropdownSearchRef} className="flex items-center gap-2 p-2 bg-white rounded-md top-full">
        <SearchNormal1 size={16} className="text-gray-400" />
        <input type="text" placeholder="Search A Country" className="w-full text-xs bg-transparent outline-none" value={searchQuery} onChange={handleSearch} onClick={(e) => e.stopPropagation()} />
      </div>
      {filteredCountries.length ? (
        <div ref={dropdownRef} className={` ${className}`} onMouseDown={(e) => e.preventDefault()}>
          <CustomCountryList
            countries={filteredCountries}
            onSelect={(country) => {
              onSelect(country);
              onClose();
            }}
            selectedCountry={selectedCountry}
          />
        </div>
      ) : (
        <div className="py-2">
          <span className="px-8 text text-english-vermillion-300">{searchQuery} Not Found</span>
        </div>
      )}
    </div>
  );
};

export default CustomCountrySelectorDropdown;

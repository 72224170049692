import instance from "./axiosInstance";

export const logOutUser = async (accessToken: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get("/api/v1/auth/signout");
    return response.data;
  } catch (error) {
    throw error;
  }
};

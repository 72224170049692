import React, { createContext, useState, useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { autoTaggingService } from "../utilities/api/autoTaggingService";
import { ClothesDetectionResult, ClothingItem } from "../utilities/types/free-trial/auto-tagging-service/types";

interface AutoTaggingServiceContextValue {
  uploadedImage: string | null;
  imageDimensions: { width: number; height: number } | null;
  scaleFactor: { x: number; y: number } | null;
  currentStep: number;
  canProceedToNextStep: boolean;
  autoTaggingResult: ClothesDetectionResult | null;
  hoveredItem: ClothingItem | null;
  error: string | null;
  setError: (error: string | null) => void;
  handlePageChange: (page: number) => void;
  handleImageUpload: (image: File | undefined) => void;
  setCanProceedToNextStep: (value: boolean) => void;
  submitAutoTagging: () => Promise<void>;
  resetAutoTaggingService: () => void;
  setHoveredItem: (item: ClothingItem | null) => void;
  getAdjustedCoordinates: (region: ClothingItem["Region"]) => { X1: number; Y1: number; X2: number; Y2: number };
}

export const AutoTaggingServiceContext = createContext<AutoTaggingServiceContextValue | undefined>(undefined);

export const AutoTaggingServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);
  const [scaleFactor, setScaleFactor] = useState<{ x: number; y: number } | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false);
  const [autoTaggingResult, setAutoTaggingResult] = useState<ClothesDetectionResult | null>(null);
  const [hoveredItem, setHoveredItem] = useState<ClothingItem | null>(null);
  const { accessToken } = useContext(AuthContext);
  const [error, setError] = useState<string | null>(null);

  const handlePageChange = (page: number) => {
    setCurrentStep(page);
    setCanProceedToNextStep(false);
  };

  const handleImageUpload = useCallback(async (image: File | undefined) => {
    if (image) {
      const base64 = await convertToBase64(image);
      setUploadedImage(base64);

      const img = new Image();
      img.onload = () => {
        const originalWidth = img.width;
        const originalHeight = img.height;
        setImageDimensions({ width: originalWidth, height: originalHeight });

        const containerWidth = 240; // w-[10rem]
        const containerHeight = 400; // h-[17.375rem]
        const scaleX = containerWidth / originalWidth;
        const scaleY = containerHeight / originalHeight;
        setScaleFactor({ x: scaleX, y: scaleY });
      };
      img.src = URL.createObjectURL(image);

      setCanProceedToNextStep(true);
    }
  }, []);

  const submitAutoTagging = async () => {
    if (!uploadedImage || !accessToken) {
      setError("No image uploaded or access token missing");
      return;
    }

    try {
      const response = await autoTaggingService(accessToken, uploadedImage);
      if (response.ok) {
        setAutoTaggingResult(response.data.result);
        setCanProceedToNextStep(true);
        setCurrentStep(2);
        setError(null);
      } else {
        setError(response.message || "Failed to process image");
        setCurrentStep(1);
      }
    } catch (error) {
      console.error("Error submitting clothes recognition:", error);
      setError("An error occurred during clothes recognition. Please try again.");
      setCurrentStep(1);
    }
  };

  const resetAutoTaggingService = () => {
    setUploadedImage(null);
    setImageDimensions(null);
    setScaleFactor(null);
    setCurrentStep(0);
    setCanProceedToNextStep(false);
    setAutoTaggingResult(null);
    setHoveredItem(null);
    setError(null);
  };

  const getAdjustedCoordinates = useCallback(
    (region: ClothingItem["Region"]) => {
      if (!imageDimensions || !scaleFactor) return region;
      return {
        X1: (region.X1 / imageDimensions.width) * 100,
        Y1: (region.Y1 / imageDimensions.height) * 100,
        X2: (region.X2 / imageDimensions.width) * 100,
        Y2: (region.Y2 / imageDimensions.height) * 100,
      };
    },
    [imageDimensions, scaleFactor]
  );

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve(base64.split(",")[1]);
      };
      reader.onerror = (error) => {
        console.error("Error converting to base64:", error);
        reject(error);
      };
    });
  };

  return (
    <AutoTaggingServiceContext.Provider
      value={{
        uploadedImage,
        imageDimensions,
        scaleFactor,
        currentStep,
        canProceedToNextStep,
        autoTaggingResult,
        hoveredItem,
        error,
        setError,
        handlePageChange,
        handleImageUpload,
        setCanProceedToNextStep,
        submitAutoTagging,
        resetAutoTaggingService,
        setHoveredItem,
        getAdjustedCoordinates,
      }}
    >
      {children}
    </AutoTaggingServiceContext.Provider>
  );
};

export const useAutoTaggingServiceContext = () => {
  const context = useContext(AutoTaggingServiceContext);
  if (!context) {
    throw new Error("useAutoTaggingServiceContext must be used within an AutoTaggingServiceProvider");
  }
  return context;
};

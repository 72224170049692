import { useMemo } from "react";

export const useHumanDate = () => {
  const formatTimestamp = useMemo(
    () => (timestamp: number): string => {
      const date = new Date(timestamp * 1000);
      return date.toLocaleString("en-US", { hour: "numeric", hour12: true });
    },
    []
  );

  return { formatTimestamp };
};

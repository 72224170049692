import React, { createContext, useState, useEffect, useContext } from "react";
import { DashboardMapData, DashboardResponse } from "../utilities/types/mianDashboard/types";
import { getDashboardMap } from "../utilities/api/dashboardMapService";
import { AuthContext } from "./AuthContext";

interface DashboardContextType {
  dashboardMap: DashboardMapData | null;
  loading: boolean;
  fetchDashboardMap: () => Promise<void>;
}

export const DashboardContext = createContext<DashboardContextType>({
  dashboardMap: null,
  loading: true,
  fetchDashboardMap: async () => {},
});

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [dashboardMap, setDashboardMap] = useState<DashboardMapData | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchDashboardMap = async () => {
    if (!accessToken) return;
    setLoading(true);
    try {
      const response: DashboardResponse = await getDashboardMap(accessToken);
      setDashboardMap(response.data);
    } catch (error) {
      console.error("Error fetching dashboard map:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardMap();
  }, [accessToken]);

  return <DashboardContext.Provider value={{ dashboardMap, loading, fetchDashboardMap }}>{children}</DashboardContext.Provider>;
};

import instance from "./axiosInstance";
import { CheckoutResponse, CheckoutParams, PaymentMethod } from "../types/payment/types";

interface CreatePaymentCheckoutParams {
  accessToken: string;
  paymentMethod: PaymentMethod;
  params: CheckoutParams;
}

export const createPaymentCheckout = async ({ accessToken, paymentMethod, params }: CreatePaymentCheckoutParams): Promise<CheckoutResponse> => {
  try {
    const response = await instance.post<CheckoutResponse>(`/api/v1/${paymentMethod}/checkout`, params, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error creating payment checkout:", error);
    throw error;
  }
};

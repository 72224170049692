import NavbarLeftSide from "./NavbarLeftSide";
import NavbarRightSide from "./NavbarRightSide";

const Navbar = () => {
  return (
    <nav className="sticky inset-0 flex justify-between px-10 py-4 border-b border-gorgonzola-blue-100 bg-[#F9F9FA] z-50">
      <NavbarLeftSide />
      <NavbarRightSide />
    </nav>
  );
};

export default Navbar;

import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from "chart.js";
import { Transaction } from "../../utilities/types/mianDashboard/types";

ChartJS.register(CategoryScale, LinearScale, BarElement);

interface TransactionChartProps {
  transactions: Transaction[];
}

const TransactionChart: React.FC<TransactionChartProps> = ({ transactions }) => {
  const chartTransactions = Array(8)
    .fill(null)
    .map((_, index) => transactions[transactions.length - 8 + index] || { amount: "0" });

  const chartData = {
    labels: chartTransactions.map((_, index) => `${index + 1}`),
    datasets: [
      {
        data: chartTransactions.map((t) => parseFloat(t.amount)),
        backgroundColor: "#474bcf",
        borderRadius: 25,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
    barThickness: 5,
  };

  return (
    <div style={{ width: "8.8125rem", height: "2.9375rem" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TransactionChart;

import React from "react";
import { ArrowDown, ArrowUp } from "iconsax-react";

interface LastTransactionProps {
  action: "deposit" | "withdraw";
  amount: string;
}

const LastTransaction: React.FC<LastTransactionProps> = ({ action, amount }) => {
  const isDeposit = action === "deposit";

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center gap-2">
        <span className={`grid place-content-center p-2 rounded-full size-5 *:size-4 ${isDeposit ? "bg-shindig-100" : "bg-english-vermillion-100"}`}>
          {isDeposit ? <ArrowUp className="rotate-45 text-shindig-600" variant="Broken" /> : <ArrowDown className="-rotate-45 text-english-vermillion-600" variant="Broken" />}
        </span>
        <span className="text-xs font-bold text-gorgonzola-blue-300">${parseFloat(amount)}</span>
      </div>
      <span className="capitalize text-2xs text-closed-shutter-300">Latest Transaction</span>
    </div>
  );
};

export default LastTransaction;

import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import ReusableModal from "./ReusableModal";
import MainButton from "../button/MainButton";
import { ArrowLeft } from "iconsax-react";
import { enqueueSnackbar } from "notistack";
import { PaymentMethod } from "../../utilities/types/payment/types";
import { createPaymentCheckout } from "../../utilities/api/paymentService";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  checkoutData: any;
  checkoutId: number | null;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, checkoutData, checkoutId }) => {
  const { accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);

  if (!checkoutData || checkoutId === null) {
    return null;
  }
  console.log(checkoutId);
  const handleConfirm = async () => {
    if (!accessToken) {
      enqueueSnackbar("Please log in to make a purchase.", { variant: "error" });
      return;
    }

    if (!selectedPaymentMethod) {
      enqueueSnackbar("Please select a payment method.", { variant: "error" });
      return;
    }

    setIsLoading(true);
    try {
      const response = await createPaymentCheckout({
        accessToken,
        paymentMethod: selectedPaymentMethod,
        params: {
          product_id: checkoutId,
          product_type: "plan",
        },
      });

      if (response.ok) {
        window.location.href = response.data.pay_url;
      } else {
        enqueueSnackbar(response.message || "Unable to create checkout session. Please try again.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      enqueueSnackbar("An error occurred during checkout. Please try again later.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="flex flex-col items-center gap-8 bg-white p-6 rounded-md min-w-[33.5625rem] max-w-[90%] max-h-[90%] overflow-y-auto">
        <div className="grid w-full grid-cols-3 gap-4">
          <div className="flex flex-col items-center col-span-2 gap-4">
            <div className="w-full overflow-hidden rounded">
              <img src="/assets/image/plan/ConfirmationModalLogo.png" alt="Confirmation Modal Logo" />
            </div>
            <div className="flex flex-col gap-1 p-4 bg-[#ededfa80] w-full rounded border border-gorgonzola-blue-100 *:flex *:flex-col *:gap-1">
              <div>
                <h5 className="text-2xs text-closed-shutter-300/70">Invoice to:</h5>
                <span className="text-xs capitalize text-gorgonzola-blue-300 ">{checkoutData.from?.company_name}</span>
              </div>
              <div>
                <h5 className="text-2xs text-closed-shutter-300/70">Your email:</h5>
                <span className="text-xs text-closed-shutter-300">{checkoutData.to?.email}</span>
              </div>
              <div>
                <h5 className="text-2xs text-closed-shutter-300/70">Your name:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">{`${checkoutData.to?.first_name || ""} ${checkoutData.to?.last_name || ""}`}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between p-4 border rounded border-gorgonzola-blue-100 min-h-max">
            <div className="flex flex-col gap-1 p-3 bg-[#ededfa80] rounded">
              <h5 className="text-2xs text-closed-shutter-300/70">Issued:</h5>
              <span className="text-xs capitalize text-closed-shutter-300">
                {new Date(checkoutData.created_at * 1000).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
              </span>
            </div>
            <span className="w-full h-px bg-gorgonzola-blue-100" />
            <div className="flex flex-col justify-between gap-2">
              <h5 className="text-2xs text-closed-shutter-300/70">Amount due:</h5>
              <span className="font-bold capitalize text-closed-shutter-300">${checkoutData.plan?.price}</span>
              <span className="text-2xs text-closed-shutter-300/70">
                {new Date(checkoutData.created_at * 1000 + 30 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="flex justify-between border mb-2 text-2xs text-[#868DA6] w-full rounded">
            <span className="w-1/2 p-3 text-left">Description</span>
            <span className="px-4 py-3 text-center">Requests</span>
            <span className="px-4 py-3 text-center">Price</span>
            <span className="px-4 py-3 text-center">Total</span>
          </div>
          <div className="flex justify-between text-xs w-full *:text-center">
            <span className="w-1/2 p-3 font-bold rounded bg-gorgonzola-blue-50 text-closed-shutter-300">{checkoutData.plan?.name}</span>
            <span className="p-3 bg-transparent rounded text-closed-shutter-300/70">{checkoutData.plan?.requests}</span>
            <span className="p-3 bg-transparent rounded text-closed-shutter-300/70">${checkoutData.plan?.price}</span>
            <span className="p-3 rounded bg-gorgonzola-blue-50 text-closed-shutter-300">${checkoutData.plan?.price}</span>
          </div>
        </div>

        <div className="flex items-center justify-between w-full px-4 py-3 border rounded">
          <span className="text-xs text-closed-shutter-300/70">Invoice Total</span>
          <span className="font-bold text-gorgonzola-blue-300">${checkoutData.plan?.price}</span>
        </div>

        <div className="flex items-center w-full gap-2">
          <h5 className="text-2xs text-closed-shutter-300/70">P.method:</h5>
          <div className="flex flex-wrap gap-2">
            {checkoutData.payment_methods?.map((method: any) => (
              <label
                key={method.id}
                className={`flex items-center gap-2 px-2 py-1 border rounded-md transition-colors duration-600 cursor-pointer ${
                  selectedPaymentMethod === method.name ? "bg-gorgonzola-blue-50 border-gorgonzola-blue-200" : " bg-closed-shutter-50 border-closed-shutter-100 hover:border-closed-shutter-200"
                } `}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value={method.name}
                  checked={selectedPaymentMethod === method.name}
                  onChange={() => setSelectedPaymentMethod(method.name)}
                  className="hidden"
                />
                <div className="flex items-center gap-2">
                  <img src={method.pay_operator_logo_url} alt={method.name} className="w-8 h-6" />
                  <span className="text-xs capitalize text-closed-shutter-300">{method.name}</span>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="flex justify-center w-full gap-8">
          <MainButton type="ghostOutlined" onClick={onClose} disabled={isLoading}>
            <ArrowLeft className="size-6" variant="Broken" />
            Back
          </MainButton>
          <MainButton type="primary" onClick={handleConfirm} disabled={isLoading || !selectedPaymentMethod}>
            {isLoading ? "Processing..." : `Pay $${checkoutData.plan?.price}`}
            {!isLoading && (
              <span className="ml-1">
                <img src="/assets/image/plan/money-send.svg" alt="money-send" />
              </span>
            )}
          </MainButton>
        </div>
      </div>
    </ReusableModal>
  );
};

export default ConfirmationModal;

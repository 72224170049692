const VisualClothesService = () => {
  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-6">
        <h2 className="font-bold text-closed-shutter-300">Coming Soon...</h2>
        
        <span></span>
      </div>
    </div>
  );
};

export default VisualClothesService;

import React from "react";
import ReusableModal from "../modal/ReusableModal";
import { ClothesDetectionResult } from "../../utilities/types/free-trial/auto-tagging-service/types";
import { MeasurementResult } from "../../utilities/types/measurement-data/types";
import BodyMeasurementResult from "./BodyMeasurementResult";
import ClothesRecognitionResult from "./ClothesRecognitionResult";

interface ServiceDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceData: any;
  serviceName: string;
}

const ServiceDetailsModal: React.FC<ServiceDetailsModalProps> = ({ isOpen, onClose, serviceData, serviceName }) => {
  const renderContent = () => {
    if (serviceName === "Clothes Recognition") {
      const clothesData = serviceData.response.Data as ClothesDetectionResult;
      return <ClothesRecognitionResult clothesData={clothesData} />;
    } else if (serviceName === "Size Measurement") {
      return <BodyMeasurementResult measurementData={serviceData.response.Data as MeasurementResult} />;
    }
    return null;
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col w-full gap-6 bg-white rounded-lg min-w-[22.375rem]">
        <h2 className="py-2 font-bold text-closed-shutter-300">{serviceName} Result</h2>
        {serviceData.response ? (
          renderContent()
        ) : (
          <div className="p-4 text-center">
            <p className="font-bold text-english-vermillion-300">No data available for this service.</p>
            <p className="mt-2 text-xs text-closed-shutter-200">Please try again or contact support if the issue persists.</p>
          </div>
        )}
      </div>
    </ReusableModal>
  );
};

export default ServiceDetailsModal;

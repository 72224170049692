import React, { useState, useEffect, useMemo, useContext } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import { AuthContext } from "../../contexts/AuthContext";
import { getRequestCharts } from "../../utilities/api/requestChartsService";
import Select, { components } from "react-select";
import { ArrowDown2, ArrowRight2, People } from "iconsax-react";
import { useTimestamp } from "../../hooks/useTimestamp";
import Loader from "../loading/Loader";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ProjectDeliveriesChartProps {
  userCreatedAt: number;
}

interface ChartDataEntry {
  period: number;
  total: number;
  success: number;
  error: number;
}

const ProjectDeliveriesChart: React.FC<ProjectDeliveriesChartProps> = ({ userCreatedAt }) => {
  const { getTimestamp } = useTimestamp();
  const { accessToken } = useContext(AuthContext);
  const [chartData, setChartData] = useState<ChartDataEntry[] | null>(null);
  const [selectedDate, setSelectedDate] = useState<{ value: string; label: string } | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const dateOptions = useMemo(() => {
    const options = [];
    const creationDate = new Date(userCreatedAt * 1000);
    const currentDate = new Date();

    for (let date = new Date(creationDate); date <= currentDate; date.setMonth(date.getMonth() + 1)) {
      const value = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;
      const label = date.toLocaleString("default", { month: "long", year: "numeric" });
      options.push({ value, label });
    }

    return options.reverse();
  }, [userCreatedAt]);

  useEffect(() => {
    if (dateOptions.length > 0 && !selectedDate) {
      setSelectedDate(dateOptions[0]);
    }
  }, [dateOptions, selectedDate]);

  useEffect(() => {
    const fetchCharts = async () => {
      if (!accessToken || !selectedDate) return;

      const [year, month] = selectedDate.value.split("-");
      const lastDay = new Date(parseInt(year), parseInt(month), 0).getDate();

      const fromDate = getTimestamp(new Date(`${year}-${month}-01`));
      const toDate = getTimestamp(new Date(`${year}-${month}-${lastDay}`));

      try {
        const response = await getRequestCharts(accessToken, {
          from_date: fromDate.toString(),
          to_date: toDate.toString(),
          point: "daily",
          filter: "success",
          client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        if (response.ok) {
          setChartData(response.data.chart as ChartDataEntry[]);
        } else {
          console.error("Error fetching charts:", response.message);
        }
      } catch (error) {
        console.error("Failed to fetch charts:", error);
      }
    };

    fetchCharts();
  }, [accessToken, selectedDate]);

  const chartDataFormatted: ChartData<"line"> = useMemo(() => {
    if (!chartData || chartData.length === 0)
      return {
        labels: [],
        datasets: [
          { label: "Success", data: [] },
          { label: "Failed", data: [] },
        ],
      };

    // Sort data by date
    const sortedData = [...chartData].sort((a, b) => a.period - b.period);

    // Get the first day of the month
    const firstDay = new Date(sortedData[0].period * 1000);
    const daysInMonth = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0).getDate();

    // Create labels for each day of the month
    const labels = Array.from({ length: daysInMonth }, (_, i) => {
      const date = new Date(firstDay.getFullYear(), firstDay.getMonth(), i + 1);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`; // Always return the full label
    });

    // Create datasets
    const successData = new Array(daysInMonth).fill(0);
    const failedData = new Array(daysInMonth).fill(0);

    sortedData.forEach((entry) => {
      const date = new Date(entry.period * 1000);
      const dayIndex = date.getDate() - 1;
      successData[dayIndex] = entry.success;
      failedData[dayIndex] = entry.error;
    });

    return {
      labels,
      datasets: [
        {
          label: "Success",
          data: successData,
          borderColor: "#006557",
          backgroundColor: "#006557",
          tension: 0.5,
          pointRadius: 0,
        },
        {
          label: "Failed",
          data: failedData,
          borderColor: "#7C2B2D",
          backgroundColor: "#7C2B2D",
          tension: 0.5,
          pointRadius: 0,
        },
      ],
    };
  }, [chartData]);

  const maxY = useMemo(() => {
    if (!chartData || chartData.length === 0) return 30;
    const allValues = chartData.flatMap((d) => [d.success, d.error]);
    return Math.max(Math.ceil(Math.max(...allValues) / 5 + 5) * 5, 30);
  }, [chartData]);

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#fff",
        titleColor: "#484554c2",
        titleFont: {
          size: 15,
          weight: "bold",
        },
        titleAlign: "center",
        bodyColor: "#23262F",
        bodyFont: {
          size: 15,
          weight: "bolder",
        },
        bodyAlign: "left",
        bodySpacing: 8,
        borderColor: "#C6C7F0",
        borderWidth: 1,
        cornerRadius: 16,
        padding: 12,
        usePointStyle: true,
        boxPadding: 8,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.parsed.y;
            if (label === "Success") {
              return ` Success: ${value}`;
            } else if (label === "Failed") {
              return ` Failed: ${value}`;
            }
            return "";
          },
          labelTextColor: function (context) {
            return context.dataset.borderColor as string;
          } as (context: any) => string | CanvasGradient | CanvasPattern,
          title: (tooltipItems) => {
            if (tooltipItems.length > 0) {
              return tooltipItems[0].label;
            }
            return "";
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          callback: function (value, index, values) {
            const day = index + 1;
            return day === 1 || day % 5 === 0 ? this.getLabelForValue(value as number) : "";
          },
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: true,
          color: "#EDEDFA",
          lineWidth: 1,
          drawTicks: false,
          drawOnChartArea: true,
        },
        min: 0,
        max: maxY,
        ticks: {
          stepSize: 5,
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      padding: "0.25rem 0.5rem",
      border: "1px solid #EDEDFA",
      borderRadius: "0.375rem",
      boxShadow: "none",
      transition: "0.6s linear",
      "&:hover": {
        border: "1px solid #C6C7F0",
      },
      cursor: "pointer",
    }),
    option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#EDEDFA" : "white",
      padding: "0.5rem",
      color: "#484554c2",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#EDEDFA",
      },
      borderBottom: "1px solid #C6C7F0",
      "&:last-child": {
        borderBottom: "none",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      overflow: "hidden",
      borderRadius: "0.375rem",
      boxShadow: "none",
      border: "1px solid #C6C7F0",
      "&:hover": {
        borderColor: "#C6C7F0",
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: "15rem",
      padding: 0,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        margin: "0.5rem 0",
        width: "0.75rem",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EDEDFA",
        borderRadius: "1rem",
      },
      "&::-webkit-scrollbar-thumb": {
        position: "relative",
        background: "#FFFFFF",
        border: "1px solid #E3E4F8",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#FFFFFF",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#484554c2",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#A0AEC0",
    }),
  };
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <ArrowDown2 variant="Broken" className="size-5 text-closed-shutter-300" /> : <ArrowRight2 variant="Broken" className="size-5 text-closed-shutter-300" />}
      </components.DropdownIndicator>
    );
  };
  return (
    <div className="relative flex flex-col flex-grow gap-4 p-4 overflow-hidden border rounded-md min-h-[22.25rem] max-h-max border-gorgonzola-blue-50 ">
      <div className="flex items-center justify-between">
        <h4 className="font-bold text-closed-shutter-300">Request Seccess Rate</h4>

        <span className="p-2 transition-colors rounded-md duration-600 bg-closed-shutter-50 hover:bg-closed-shutter-200 text-closed-shutter-300 hover:text-closed-shutter-500 group">
          <People className="transition-transform size-6 group-hover:scale-105 duration-600" variant="Broken" />
        </span>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex justify-end mb-4 space-x-6">
          <div className="flex items-center">
            <div className="w-3 h-3 mr-2 rounded-full bg-shindig-600"></div>
            <span className="text-xs text-gray-600">Success</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 mr-2 rounded-full bg-english-vermillion-600"></div>
            <span className="text-xs text-gray-600">Failed</span>
          </div>
        </div>

        {/* Custom Select component */}
        <div className="select-none">
          <Select
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue as { value: string; label: string })}
            options={dateOptions}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            isSearchable={false}
          />
        </div>
      </div>
      {chartData && chartDataFormatted.datasets[0].data.length > 0 ? (
        <div className="w-full h-full">
          <Line options={options} data={chartDataFormatted} />
        </div>
      ) : (
        <div className="flex justify-center w-full h-full p-6">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ProjectDeliveriesChart;

import { PlanUsageData } from "../types/plan/types";
import instance from "./axiosInstance";

export const getMyApisChart = async (
  accessToken: string,
  params: {
    from_date: string;
    to_date: string;
    point: "daily" | "hourly";
    label_id?: number;
    client_timezone?: string;
  }
) => {
  try {
    const response = await instance.get("/api/v1/history/api_charts", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching API charts:", error);
    throw error;
  }
};

export const getActivedOrders = async (accessToken: string): Promise<{ ok: boolean; data: PlanUsageData[]; message?: string }> => {
  try {
    const response = await instance.get<{ ok: boolean; data: PlanUsageData[]; message?: string }>("/api/v1/orders/actived", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching actived orders:", error);
    throw error;
  }
};

interface CheckoutData {
  from: {
    company_name: string;
    emails: string[];
    phones: string[];
    addresses: string[];
    country: string;
  };
  to: {
    id: number;
    first_name: string;
    last_name: string | null;
    email: string;
  };
  payment_methods: Array<{
    id: number;
    name: string;
    sort: number;
    attrs: {
      fee: number | null;
      is_crypto: boolean;
      is_coin: boolean;
      default_network: string | null;
      url: string;
      boost: number;
    };
    pay_operator_logo_url: string;
  }>;
  plan: {
    name: string;
    price: number;
    requests: number;
  };
  created_at: number;
}

interface CheckoutResponse {
  ok: boolean;
  message: string;
  data: CheckoutData;
  status_code: number;
}

export const getCheckout = async (accessToken: string, planId: number): Promise<CheckoutResponse> => {
  try {
    const response = await instance.get<CheckoutResponse>("/api/v1/providers/checkout", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        plan_id: planId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error, "Error fetching checkout information");
    throw error;
  }
};

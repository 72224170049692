import instance from "./axiosInstance";
import { GoogleAuthResponse, PasswordResetRequestResponse, SignInResponse, SignUpParams } from "../types/auth-service/types";

export const signInWithEmail = async (email: string, password: string, g_response: string): Promise<SignInResponse> => {
  try {
    const response = await instance.post(`/api/v1/auth/email/signin`, { email, password, g_response });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signUpWithEmail = async (firstName: string, lastName: string, email: string, password: string, g_response: string): Promise<SignUpParams> => {
  try {
    const response = await instance.post(`/api/v1/auth/email/signup`, { firstName, lastName, email, password, g_response });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const authenticateWithGoogle = async (token: string): Promise<GoogleAuthResponse> => {
  try {
    const response = await instance.post<GoogleAuthResponse>(
      "/api/v1/auth/google/join",
      { token },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during Google authentication:", error);
    throw error;
  }
};

export const requestPasswordReset = async (email: string): Promise<PasswordResetRequestResponse> => {
  try {
    const response = await instance.post<PasswordResetRequestResponse>(
      "/api/v1/auth/pass/reset",
      { email },
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during password reset request:", error);
    throw error;
  }
};

import { Instagram, Youtube } from "iconsax-react";

const TourGuideStep04 = () => {
  return (
    <div className="flex flex-col items-center w-full gap-4">
      <img src="/assets/image/tour-guide/TourGuideStep04.gif" alt="Tour Guide Step gif" className="w-[13.75rem] min-w-[13.75rem]" />
      <div className="space-y-6 text-center ">
        <h2 className="font-bold text-closed-shutter-300">Stay Connected with Us!</h2>
        <p className="text-xs text-closed-shutter-300/70">
          Follow us on Instagram, LinkedIn, and Twitter for the latest news, updates, and special offers. Don't miss out on exciting announcements and tips to get the most out of our AI-powered
          solutions for your fashion business. Join our community today and stay in the loop!
        </p>
      </div>

      <div className="flex justify-center items-center gap-3 w-full *:size-11 *:grid *:place-content-center *:transition-colors *:duration-600 *:rounded-full *:bg-gorgonzola-blue-100 *:text-gorgonzola-blue-300 *:cursor-pointer hover:*:bg-gorgonzola-blue-200">
        <a href="https://www.youtube.com/@shaku.tech.channel" target="_blank" rel="noreferrer">
          <Youtube className="size-6" variant="Bold" />
        </a>
        <a href="https://www.instagram.com/shaku.tech?igsh=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
          <Instagram className="size-6" variant="Linear" />
        </a>
        <a href="https://www.linkedin.com/company/shakutech/?viewAsMember=true" target="_blank" rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_1142_189)">
              <path
                d="M18.5195 0H1.47656C0.660156 0 0 0.644531 0 1.44141V18.5547C0 19.3516 0.660156 20 1.47656 20H18.5195C19.3359 20 20 19.3516 20 18.5586V1.44141C20 0.644531 19.3359 0 18.5195 0ZM5.93359 17.043H2.96484V7.49609H5.93359V17.043ZM4.44922 6.19531C3.49609 6.19531 2.72656 5.42578 2.72656 4.47656C2.72656 3.52734 3.49609 2.75781 4.44922 2.75781C5.39844 2.75781 6.16797 3.52734 6.16797 4.47656C6.16797 5.42187 5.39844 6.19531 4.44922 6.19531ZM17.043 17.043H14.0781V12.4023C14.0781 11.2969 14.0586 9.87109 12.5352 9.87109C10.9922 9.87109 10.7578 11.0781 10.7578 12.3242V17.043H7.79688V7.49609H10.6406V8.80078H10.6797C11.0742 8.05078 12.043 7.25781 13.4844 7.25781C16.4883 7.25781 17.043 9.23438 17.043 11.8047V17.043V17.043Z"
                fill="#474BCF"
              />
            </g>
            <defs>
              <clipPath id="clip0_1142_189">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>{" "}
        </a>
      </div>
    </div>
  );
};

export default TourGuideStep04;

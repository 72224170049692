import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { BodyMeasurementServiceProvider } from "./contexts/BodyMeasurementServiceContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { routes } from "./utilities/defaults/routes/routes";
import { AutoTaggingServiceProvider } from "./contexts/AutoTaggingServiceContext";
import { SnackbarProvider } from "notistack";
import SuccessSnackbar from "./components/notification/Success";
import ErrorSnackbar from "./components/notification/Error";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleOAuthclientId } from "./utilities/defaults/google-OAuth/client-id";
import DashboardTemplate from "./components/template/DashboardTemplate";
import HotjarTracking from "./components/HotjarTracking/HotjarTracking";

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      Components={{
        success: SuccessSnackbar,
        error: ErrorSnackbar,
      }}
    >
      <AuthProvider>
        <GoogleOAuthProvider clientId={GoogleOAuthclientId}>
          <BodyMeasurementServiceProvider>
            <AutoTaggingServiceProvider>
              <BrowserRouter>
                <HotjarTracking />
                <Routes>
                  {/* Redirect the user to the dashboard if they are authenticated, or to the login page if they are not */}
                  <Route path="/" element={isAuthenticated ? <Navigate to={routes.dashboard.path} /> : <Navigate to={routes.login.path} />} />
                  <Route path="/auth">
                    <Route path="login" element={<routes.login.component />} />
                    <Route path="register" element={<routes.register.component />} />
                    <Route path="password-reset" element={<routes.passwordReset.component />} />
                  </Route>

                  {/* Wrap protected routes with the ProtectedRoute component */}
                  <Route element={<ProtectedRoute />}>
                    <Route path={routes.dashboard.path} element={<DashboardTemplate />}>
                      {/* Nested routes for the dashboard */}
                      <Route index element={<routes.dashboard.component />} />
                      <Route path={routes.FreeTrial.path} element={<routes.FreeTrial.component />} />
                      <Route path={routes.FreeTrialDetails.path} element={<routes.FreeTrialDetails.component />} />
                      <Route path={routes.application.path} element={<routes.application.component />} />
                      <Route path={routes.usage.path} element={<routes.usage.component />} />
                      <Route path={routes.payment.path} element={<routes.payment.component />} />
                      <Route path={routes.invoiceDetails.path} element={<routes.invoiceDetails.component />} />
                      <Route path={routes.profile.path} element={<routes.profile.component />} />
                      <Route path={routes.Tutorial.path} element={<routes.Tutorial.component />} />
                      <Route path={routes.TutorialPost.path} element={<routes.TutorialPost.component />} />
                      <Route path={routes.support.path} element={<routes.support.component />} />
                      <Route path={routes.supportChat.path} element={<routes.supportChat.component />} />
                      <Route path={routes.plan.path} element={<routes.plan.component />} />
                    </Route>
                  </Route>

                  {/* Catch-all route for any unmatched paths */}
                  <Route path="*" element={<routes.notFound.component />} />
                </Routes>
              </BrowserRouter>
            </AutoTaggingServiceProvider>
          </BodyMeasurementServiceProvider>
        </GoogleOAuthProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;

import React, { FC, useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { resetPassword, doResetPassword } from "../../utilities/api/passwordService";
import TextField from "../fields/TextField";
import { SITE_KEY } from "../../utilities/defaults/recaptcha/sitekey";
import MainButton from "../button/MainButton";

interface PasswordResetFormData {
  email: string;
}

interface NewPasswordFormData {
  newPassword: string;
  confirmPassword: string;
}

const PasswordReset: FC = () => {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [sentEmail, setSentEmail] = useState("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [isResetMode, setIsResetMode] = useState(false);
  const [resetKey, setResetKey] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get("key");
    if (key) {
      setIsResetMode(true);
      setResetKey(decodeURIComponent(key));
    }
  }, [location]);

  const {
    control: emailControl,
    handleSubmit: handleEmailSubmit,
    formState: { errors: emailErrors },
    watch: watchEmail,
  } = useForm<PasswordResetFormData>({
    mode: "onChange",
    defaultValues: { email: "" },
  });

  const {
    control: passwordControl,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors },
    watch: watchPassword,
  } = useForm<NewPasswordFormData>({
    mode: "onChange",
    defaultValues: { newPassword: "", confirmPassword: "" },
  });

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaToken(value);
  };

  const onEmailSubmit: SubmitHandler<PasswordResetFormData> = async (data) => {
    setIsSubmitting(true);

    if (!captchaToken) {
      enqueueSnackbar("Please complete the reCAPTCHA verification.", { variant: "error" });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await resetPassword({
        email: data.email,
        g_response: captchaToken,
      });

      if (response.ok) {
        setIsEmailSent(true);
        setSentEmail(data.email);
        enqueueSnackbar(response.message || "Password reset instructions have been sent to your email.", { variant: "success" });
      } else {
        enqueueSnackbar(response.message || "Unable to send reset email. Please try again later.", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("An error occurred while resetting your password. Please try again.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
      recaptchaRef.current?.reset();
    }
  };

  const onPasswordSubmit: SubmitHandler<NewPasswordFormData> = async (data) => {
    setIsSubmitting(true);

    if (!resetKey) {
      enqueueSnackbar("Invalid password reset key. Please request a new reset link.", { variant: "error" });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await doResetPassword({
        key: resetKey,
        password: data.newPassword,
        confirm_password: data.confirmPassword,
      });

      if (response.ok) {
        enqueueSnackbar(response.message || "Password reset successful! You can now log in with your new password.", { variant: "success" });
        navigate("/auth/login");
      } else {
        enqueueSnackbar(response.message || "Password reset unsuccessful. Please try again or contact support.", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("An error occurred while setting your new password. Please try again.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const email = watchEmail("email");
  const isEmailFormValid = email && Object.keys(emailErrors).length === 0;

  const { newPassword, confirmPassword } = watchPassword();
  const isPasswordFormValid = newPassword && confirmPassword && Object.keys(passwordErrors).length === 0;

  const renderEmailForm = () => (
    <form onSubmit={handleEmailSubmit(onEmailSubmit)} className="flex flex-col w-full gap-6">
      <div className="flex flex-col gap-2">
        <Controller
          name="email"
          control={emailControl}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
              message: "Invalid email address",
            },
          }}
          render={({ field }) => <TextField required label="Email" placeholder="Enter your email" type="email" error={!!emailErrors.email} success={!!email && !emailErrors.email} {...field} />}
        />
      </div>

      <div className="flex justify-center">
        <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} onChange={handleCaptchaChange} />
      </div>

      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col justify-end w-full gap-4">
          <MainButton type="primary" isSubmit disabled={!isEmailFormValid || isSubmitting} customClass="min-w-full justify-center !min-h-14">
            {isSubmitting ? "Submitting..." : "Reset Password"}
          </MainButton>
        </div>

        <div className="space-x-2 text-xs">
          <span className="text-closed-shutter-300">Remember your password?</span>
          <span className="text-gorgonzola-blue-300">
            <Link to="/auth/login">Sign In</Link>
          </span>
        </div>
        <div className="space-x-2 text-xs">
          <span className="text-closed-shutter-300">Don't have an account?</span>
          <span className="text-gorgonzola-blue-300">
            <Link to="/auth/register">Sign up</Link>
          </span>
        </div>
      </div>
    </form>
  );

  const renderPasswordForm = () => (
    <form onSubmit={handlePasswordSubmit(onPasswordSubmit)} className="flex flex-col w-full gap-6">
      <Controller
        name="newPassword"
        control={passwordControl}
        rules={{
          required: "New password is required",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters long",
          },
        }}
        render={({ field }) => (
          <TextField
            required
            label="New Password"
            placeholder="Enter your new password"
            type="password"
            showPasswordHint
            error={!!passwordErrors.newPassword}
            success={!!newPassword && !passwordErrors.newPassword}
            {...field}
          />
        )}
      />

      <Controller
        name="confirmPassword"
        control={passwordControl}
        rules={{
          required: "Confirm password is required",
          validate: (value) => value === newPassword || "The passwords do not match",
        }}
        render={({ field }) => (
          <TextField
            required
            label="Confirm Password"
            placeholder="Confirm your new password"
            type="password"
            error={!!passwordErrors.confirmPassword}
            success={!!confirmPassword && !passwordErrors.confirmPassword}
            {...field}
          />
        )}
      />

      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col justify-end w-full gap-4">
          <MainButton type="primary" isSubmit disabled={!isPasswordFormValid || isSubmitting} customClass="min-w-full justify-center !min-h-14">
            {isSubmitting ? "Submitting..." : "Set New Password"}
          </MainButton>
        </div>
      </div>
    </form>
  );

  const renderEmailSentMessage = () => (
    <div className="flex flex-col items-center gap-20 w-full lg:ps-10 lg:w-[21rem]">
      <div className="space-y-2">
        <h1 className="text-lg font-bold text-closed-shutter-300">Reset Password</h1>
        <p className="text-xs font-normal text-closed-shutter-200">Please Open Your Email</p>
      </div>

      <div className="flex flex-col items-center gap-3">
        <img src="/assets/image/password/Reset Password.svg" alt="Reset Password" />
        <p className="text-xs text-center text-closed-shutter-300">
          We have sent an email to
          <a href={`mailto:${sentEmail}`} className="px-1 text-gorgonzola-blue-300">
            {sentEmail}
          </a>
          with instructions to reset your password.
        </p>
      </div>

      <div className="flex flex-col items-center gap-6">
        <div className="space-x-2 text-xs">
          <span className="text-closed-shutter-300">Didn't receive the email?</span>
          <span className="cursor-pointer text-gorgonzola-blue-300" onClick={() => setIsEmailSent(false)}>
            Try again
          </span>
        </div>
        <div className="space-x-2 text-xs">
          <span className="text-closed-shutter-300">Remember your password?</span>
          <span className="text-gorgonzola-blue-300">
            <Link to="/auth/login">Sign In</Link>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative flex items-center justify-center w-full min-h-screen gap-10 bg-white xxl:gap-20">
      <span className="absolute inset-0 -left-[1.7rem] size-[20.5rem] blur-[175px] rounded-full z-10 bg-[#474bcf33]" />
      <div className="z-20 flex flex-col h-full gap-8 px-6 py-16 lg:gap-28">
        <div className="flex justify-center lg:justify-start">
          <img src="/assets/image/authenticate/mobile-logo.svg" alt="Logo" className="w-[6.125rem] block lg:hidden" />
          <img src="/assets/image/authenticate/desktop-logo.svg" alt="Logo" className="w-[11rem] hidden lg:block" />
        </div>
        <div className="flex flex-col items-center gap-20 w-full lg:ps-10 lg:w-[21rem] *:w-full ">
          {isResetMode ? (
            <>
              <div className="space-y-2">
                <h1 className="text-lg font-bold text-closed-shutter-300">Set New Password</h1>
                <p className="text-xs font-normal text-closed-shutter-200">Enter your new password below.</p>
              </div>
              {renderPasswordForm()}
            </>
          ) : isEmailSent ? (
            renderEmailSentMessage()
          ) : (
            <>
              <div className="space-y-2">
                <h1 className="text-lg font-bold text-closed-shutter-300">Reset Password</h1>
                <p className="text-xs font-normal text-closed-shutter-200">Enter your email address to reset your password.</p>
              </div>
              {renderEmailForm()}
            </>
          )}
        </div>
      </div>
      <div className="flex-grow hidden px-6 py-16 lg:block max-w-[40rem]">
        <img src="/assets/image/authenticate/auth-pattern.png" alt="pattern" className="min-w-full" />
      </div>
    </div>
  );
};

export default PasswordReset;

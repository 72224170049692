import { ApplicationResponse } from "../types/application/types";
import instance from "./axiosInstance";
import { AxiosResponse } from "axios";

// Create Application
export const createApplication = async (accessToken: string): Promise<ApplicationResponse> => {
  try {
    const response: AxiosResponse<ApplicationResponse> = await instance.post("/api/v1/applications", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating application:", error);
    throw error;
  }
};

// Get Application
export const getApplication = async (accessToken: string): Promise<ApplicationResponse> => {
  try {
    const response: AxiosResponse<ApplicationResponse> = await instance.get("/api/v1/applications", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting application:", error);
    throw error;
  }
};

// Delete Application
export const deleteApplication = async (accessToken: string): Promise<ApplicationResponse> => {
  try {
    const response: AxiosResponse<ApplicationResponse> = await instance.delete("/api/v1/applications", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting application:", error);
    throw error;
  }
};

import { AxiosResponse } from "axios";
import instance from "./axiosInstance";
import { MeasurementDataResponse, MeasurementRequest } from "../types/measurement-data/types";



export const submitSizeMeasurement = async (accessToken: string, data: MeasurementRequest): Promise<MeasurementDataResponse> => {
  try {
    const response: AxiosResponse<MeasurementDataResponse> = await instance.post(`/api/v1/services/sizeMeasurement`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending size measurement:", error);
    throw error;
  }
};

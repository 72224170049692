import { ApiResponse, ChangePasswordParams, DoResetPasswordParams, ResetPasswordParams, VerifyChangePasswordParams } from "../types/auth-service/password/types";
import instance from "./axiosInstance";

export const resetPassword = async (params: ResetPasswordParams): Promise<ApiResponse> => {
  try {
    const response = await instance.post<ApiResponse>(
      "/api/v1/auth/pass/reset",
      {
        email: params.email,
        referer: "bpanel",
        g_response: params.g_response,
      },
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

export const doResetPassword = async (params: DoResetPasswordParams): Promise<ApiResponse> => {
  try {
    const response = await instance.put<ApiResponse>("/api/v1/auth/pass/doReset", params, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error doing reset password:", error);
    throw error;
  }
};

////////////////////////////////////////////////////// from panel //////////////////////////////////////////////////////
export const changePassword = async (accessToken: string, params: ChangePasswordParams): Promise<ApiResponse> => {
  try {
    const response = await instance.post<ApiResponse>("/api/v1/auth/changePassword", params, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

export const verifyChangePassword = async (accessToken: string, params: VerifyChangePasswordParams): Promise<ApiResponse> => {
  try {
    const response = await instance.post<ApiResponse>("/api/v1/auth/verifyChangePassword", params, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error verifying password change:", error);
    throw error;
  }
};

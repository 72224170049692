import React from "react";
import { DashboardProvider } from "../../contexts/DashboardContext";
import Usage from "./Usage";

const UsageProvider = () => {
  return (
    <DashboardProvider>
      <Usage />
    </DashboardProvider>
  );
};

export default UsageProvider;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HOW_TO_WORK_POSTS } from "../../utilities/defaults/tutorial/tutorial-posts";
import { Clock } from "iconsax-react";

const Tutorial: React.FC = () => {
  const [activeFilter, setActiveFilter] = useState<string>("all");

  const filteredPosts = activeFilter === "all" ? HOW_TO_WORK_POSTS : HOW_TO_WORK_POSTS.filter((post) => post.tag === activeFilter);

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="p-6">
        <h2 className="font-bold text-closed-shutter-300">Tutorial</h2>
      </div>
      <ComingSoonWrapper>
        <div className="p-6">
          <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
            <div className="p-6">
              <div className="flex gap-4 mb-6">
                <button
                  className={`px-3 py-2 rounded text-xs border ${activeFilter === "all" ? "bg-gorgonzola-blue-300 text-white" : "bg-white text-[#344054]  border-[#D0D5DD]"}`}
                  onClick={() => setActiveFilter("all")}
                >
                  All
                </button>
                <button
                  className={`px-3 py-2 rounded text-xs flex items-center gap-2 border ${
                    activeFilter === "body_measurment" ? "bg-white text-[#344054]  border-[#F7BA1E]" : "bg-white text-[#344054] border-[#D0D5DD]"
                  }`}
                  onClick={() => setActiveFilter("body_measurment")}
                >
                  <span className="size-6 rounded-full bg-[#F7BA1E]" />
                  Body Measurement
                </button>
                <button
                  className={`px-3 py-2 rounded text-xs flex items-center gap-2 border ${
                    activeFilter === "auto_tagging" ? "bg-white text-[#344054]  border-gorgonzola-blue-300" : "bg-white text-[#344054] border-[#D0D5DD]"
                  }`}
                  onClick={() => setActiveFilter("auto_tagging")}
                >
                  <span className="size-6 rounded-full bg-[#3491FA]" />
                  Auto Tagging
                </button>
              </div>

              <div className="gap-6 columns-1 md:columns-2 lg:columns-3">
                {filteredPosts.map((post) => (
                  <Link to={`/dashboard/tutorial/${post.id}`} key={post.id}>
                    <div className="mb-6">
                      <div className="flex flex-col gap-4 p-4 overflow-hidden border rounded-lg border-gorgonzola-blue-50 transition-shadow duration-600 shadow-[0px_17.5px_70px_0px_rgba(0,_0,_0,_0.12)] hover:shadow-[0px_1.25px_4px_0px_rgba(0,_0,_0,_0.25)]">
                        {post.image ? (
                          <div className="relative overflow-hidden rounded aspect-video">
                            <img src={post.image} alt={post.image_alt} className="object-contain size-full" />
                          </div>
                        ) : null}

                        <div className="flex items-center">
                          <span className={`px-4 py-3 text-xs capitalize text-white rounded ${post.tag === "body_measurment" ? "bg-[#F7BA1E]" : "bg-gorgonzola-blue-300"}`}>{post.title}</span>
                        </div>

                        <div className="flex flex-col gap-4">
                          <h3 className="font-bold text-closed-shutter-300">{post.title}</h3>
                          <p className="text-xs text-closed-shutter-300/70">{post.description}</p>
                          <div className="flex items-center justify-between text-closed-shutter-300/70">
                            <div className="flex items-center gap-1 text-xs">
                              <Clock size={16} variant="Broken" />
                              <span>{post.reading_time} Min</span>
                            </div>
                            <span className="text-xs ">{post.created_at}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ComingSoonWrapper>
    </div>
  );
};

const ComingSoonWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 z-10 flex justify-center bg-white/50 backdrop-blur-sm">
        <span className="mt-20 text-xl font-bold text-closed-shutter-300">Coming Soon...</span>
      </div>
      <div className="overflow-hidden">{children}</div>
    </div>
  );
};

export default Tutorial;

import { FC, useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { useAuth } from "../../contexts/AuthContext";
import { signInWithEmail } from "../../utilities/api/authService";
import TextField from "../fields/TextField";
import { SITE_KEY } from "../../utilities/defaults/recaptcha/sitekey";
import MainButton from "../button/MainButton";
import GoogleButton from "../button/GoogleButton";

interface LoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login: FC = () => {
  const { googleSignIn, isAuthenticated, setIsAuthenticated } = useAuth();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<LoginFormData>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
  });

  useEffect(() => {
    const rememberedUser = localStorage.getItem("rememberedUser");
    if (rememberedUser) {
      const { email, password } = JSON.parse(rememberedUser);
      setValue("email", email);
      setValue("password", password);
      setValue("rememberMe", true);
    }
  }, [setValue]);

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const handleGoogleSuccess = async (credentialResponse: any) => {
    setIsGoogleSubmitting(true);

    const googleSignInRes = await googleSignIn(credentialResponse.access_token);
    if (googleSignInRes) {
      enqueueSnackbar("Successfully logged in with Google!", { variant: "success" });
      navigate("/dashboard");
      setIsGoogleSubmitting(false);
    } else {
      enqueueSnackbar("An error occurred. Please try again.", { variant: "error" });
      setIsGoogleSubmitting(false);
    }
  };

  const handleGoogleError = () => {
    enqueueSnackbar("Google sign-in unsuccessful. Please try again.", { variant: "error" });
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaToken(value);
  };

  const onSubmit: SubmitHandler<LoginFormData> = async (data) => {
    setIsSubmitting(true);

    if (!captchaToken) {
      enqueueSnackbar("Please complete the reCAPTCHA verification.", { variant: "error" });
      setIsSubmitting(false);
      recaptchaRef.current?.reset();
      return;
    }

    try {
      const loginResponse = await signInWithEmail(data.email, data.password, captchaToken);

      if (loginResponse.ok) {
        const userInfo = {
          user: "UserToken",
          token: loginResponse.data.access_token,
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        if (data.rememberMe) {
          localStorage.setItem("rememberedUser", JSON.stringify({ email: data.email, password: data.password }));
        } else {
          localStorage.removeItem("rememberedUser");
        }

        setIsAuthenticated(true);
        enqueueSnackbar("Successfully logged in!", { variant: "success" });
      } else {
        enqueueSnackbar("Incorrect email or password. Please try again.", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("An error occurred. Please try again.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
      recaptchaRef.current?.reset();
    }
  };

  const email = watch("email");
  const password = watch("password");
  const isFormValid = email && password && Object.keys(errors).length === 0;

  return (
    <div className="relative flex items-center justify-center w-full min-h-screen gap-10 bg-white xxl:gap-20">
      <span className=" absolute inset-0 -left-[1.7rem] size-[20.5rem] blur-[175px] rounded-full z-10 bg-[#474bcf33]" />
      <div className="z-20 flex flex-col h-full gap-8 px-6 py-16 lg:gap-28">
        <div className="flex justify-center lg:justify-start">
          <img src="/assets/image/authenticate/mobile-logo.svg" alt="Logo" className="w-[6.125rem] block lg:hidden" />
          <img src="/assets/image/authenticate/desktop-logo.svg" alt="Logo" className="w-[11rem] hidden lg:block" />
        </div>
        <div className="flex flex-col items-center gap-20 w-full lg:ps-10 lg:w-[21rem]">
          <div className="space-y-2">
            <h1 className="text-lg font-bold text-closed-shutter-300">Login</h1>
            <p className="text-xs font-normal text-closed-shutter-200">Please fill your detail to access your account.</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6 ">
            <div className="flex flex-col gap-2">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => <TextField label="Email" required placeholder="Enter your email" type="email" error={!!errors.email} success={!!watch("email") && !errors.email} {...field} />}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="password"
                control={control}
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <TextField label="Password" required placeholder="Enter your password" type="password" error={!!errors.password} success={!!watch("password") && !errors.password} {...field} />
                )}
              />
            </div>

            <div className="flex justify-center">
              <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} onChange={handleCaptchaChange} />
            </div>

            <div className="flex justify-between w-full gap-2">
              <label className="flex items-center gap-2 text-xs">
                <Controller
                  name="rememberMe"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      type="checkbox"
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value}
                      ref={ref}
                      className="w-4 h-4 transition duration-150 ease-in-out form-checkbox text-gorgonzola-blue-300"
                    />
                  )}
                />
                Remember me
              </label>
              <Link to="/auth/password-reset">
                <span className="text-xs text-gorgonzola-blue-300">Forgot Password?</span>
              </Link>
            </div>

            <div className="flex flex-col items-center gap-6">
              <div className="flex flex-col justify-end w-full gap-4">
                <MainButton type="primary" isSubmit disabled={!isFormValid || isSubmitting} customClass=" justify-center min-h-14">
                  {isSubmitting ? "Submitting..." : "Sign In"}
                </MainButton>
                <GoogleButton onSuccess={handleGoogleSuccess} onError={handleGoogleError} onSubmiting={isGoogleSubmitting} text="Sign in with Google" />
              </div>

              <div className="space-x-2 text-xs">
                <span className=" text-closed-shutter-300">You don't have an account?</span>
                <span className="text-gorgonzola-blue-300">
                  <Link to="/auth/register">Sign Up</Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex-grow hidden px-6 py-16 lg:block max-w-[40rem]">
        <img src="/assets/image/authenticate/auth-pattern.png" alt="pattern" className="min-w-full" />
      </div>
    </div>
  );
};

export default Login;

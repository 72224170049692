import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { getServicesUsageHistory, ServiceHistoryItem } from "../../utilities/api/serviceHistoryService";
import ServiceHistoryTable from "./ServiceHistoryTable";
import Loader from "../loading/Loader";
import { DashboardContext } from "../../contexts/DashboardContext";
import ProjectDeliveriesChart from "../project-deliveries-chart/ProjectDeliveriesChart";
import UserStatistics from "../user-statistics/UserStatistics";

const Usage: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const { dashboardMap, loading: dashboardLoading } = useContext(DashboardContext);
  const [serviceHistory, setServiceHistory] = useState<ServiceHistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  useEffect(() => {
    const fetchServiceHistory = async () => {
      if (!accessToken) return;

      setLoading(true);
      try {
        const response = await getServicesUsageHistory(accessToken, currentPage);
        if (response.ok) {
          setServiceHistory(response.data.data);
          setTotalPages(response.data.last_page);
          setError(null);
        } else {
          setError(response.message || "Failed to load service history.");
        }
      } catch (err) {
        console.error("Failed to fetch service history:", err);
        setError("An error occurred while fetching service history. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchServiceHistory();
  }, [accessToken, currentPage]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-6">
        <h2 className="font-bold text-closed-shutter-300">Usage</h2>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 p-6">
        <div className="flex justify-between w-full gap-4">
          {dashboardMap ? <ProjectDeliveriesChart userCreatedAt={dashboardMap.user.created_at} /> : null}
          {dashboardMap ? <UserStatistics userCreatedAt={dashboardMap.user.created_at} /> : null}
        </div>

        <div className="w-full border rounded-md border-gorgonzola-blue-50">
          <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
            <div className="flex items-center justify-between w-full px-4 py-6">
              <h2 className="font-bold text-closed-shutter-300">Service Usage History</h2>
            </div>
            {loading ? (
              <div className="flex justify-center w-full p-6">
                <Loader />
              </div>
            ) : error ? (
              <div className="text-center text-english-vermillion-300">{error}</div>
            ) : (
              <ServiceHistoryTable
                serviceHistory={serviceHistory}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                hasPreviousPage={hasPreviousPage}
                hasNextPage={hasNextPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usage;

import React from "react";
import { DocumentUpload, Eye } from "iconsax-react";
import { Ticket } from "../../utilities/types/tickets/types";
import { TableColumn } from "../../utilities/types/support/types";
import Table from "../table/Table";
import MainButton from "../button/MainButton";
import StatusBadge from "../table/StatusBadge";
import { Link } from "react-router-dom";
import { formatDate } from "../../utilities/helper/dateUtils";

interface TicketsTableProps {
  tickets: Ticket[];
}

const TicketsTable: React.FC<TicketsTableProps> = ({ tickets }) => {
  const ticketColumns: TableColumn<Ticket>[] = [
    {
      key: "id",
      header: "Id",
      render: (value: string) => (
        <div className="flex items-center gap-2">
          <span>#{value}</span>
          <DocumentUpload className="size-6" variant="Broken" />
        </div>
      ),
    },
    { key: "title", header: "Subject" },
    { key: "section", header: "Dep" },
    {
      key: "status",
      header: "Status",
      render: (value: string) => (
        <div className="flex w-full">
          <StatusBadge statusText={value === "open" ? "answered" : value === "closed" ? "failed" : "waiting"} isSuccess={value === "open" ? true : value === "closed" ? false : null} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value: number) => formatDate(new Date(value * 1000).toLocaleDateString()),
    },
    {
      key: "created_at",
      header: "Time",
      render: (value: number) => new Date(value * 1000).toLocaleTimeString(),
    },
    {
      key: "action",
      header: "Show Answer",
      render: (_, ticket: Ticket) => (
        <Link to={`/dashboard/support/${ticket.id}`} state={{ ticketId: ticket.id }} className="flex items-center justify-center">
          <MainButton type="ghostOutlined">
            <Eye variant="Broken" className="size-6" />
            Answer
          </MainButton>
        </Link>
      ),
    },
  ];

  return <Table data={tickets} columns={ticketColumns} />;
};

export default TicketsTable;

import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

type PlanType = "free" | "bodyMeasurement" | "autoTagging" | "mixed";

interface PlanButtonProps {
  type: PlanType;
  title: string;
  to?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const PlanButton: React.FC<PlanButtonProps> = ({ type, title, to, icon, onClick, disabled = false }) => {
  const baseStyles =
    "flex items-center w-full gap-4 p-3 overflow-hidden text-xs transition-colors rounded-lg outline-none cursor-pointer select-none whitespace-nowrap duration-600 disabled:bg-closed-shutter-100 disabled:text-closed-shutter-200";

  const getTypeStyles = () => {
    switch (type) {
      case "free":
        return "border bg-closed-shutter-100 text-closed-shutter-300 border-closed-shutter-300 hover:bg-slap-happy-50 hover:text-slap-happy-300 hover:border-slap-happy-300";
      case "bodyMeasurement":
        return "border bg-[#FFF9E9] text-[#AC7B00] border-[#F7BA1E] hover:bg-slap-happy-50 hover:text-slap-happy-300 hover:border-slap-happy-300";
      case "autoTagging":
        return "border bg-[#EBF4FF] text-[#004694] border-[#3491FA] hover:bg-slap-happy-50 hover:text-slap-happy-300 hover:border-slap-happy-300";
      case "mixed":
        return "border bg-gradient-to-r from-[#FFF9E9] to-[#EBF4FF] text-[#9B9835] hover:bg-slap-happy-50 hover:text-slap-happy-300 hover:border-slap-happy-300";
      default:
        return "";
    }
  };

  if (to)
    return (
      <Link to={to}>
        <button className={`${baseStyles} ${getTypeStyles()}`} onClick={onClick} disabled={disabled}>
          {icon && <span className="flex-shrink-0">{icon}</span>}
          <span>{title}</span>
        </button>
      </Link>
    );  

  return (
    <button className={`${baseStyles} ${getTypeStyles()}`} onClick={onClick} disabled={disabled}>
      {icon && <span className="flex-shrink-0">{icon}</span>}
      <span>{title}</span>
    </button>
  );
};

export default PlanButton;

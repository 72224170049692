import { InvoiceInfoResponse, WalletSummaryParams, WalletTransfersParams } from "../types/wallet/types";
import instance from "./axiosInstance";

export const getWalletTransfersHistory = async (accessToken: string, params: WalletTransfersParams = {}) => {
  try {
    const response = await instance.get("/api/v1/history/wallet/chart", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet transfers history:", error);
    throw error;
  }
};

export const getWalletSummary = async (accessToken: string, params: WalletSummaryParams = {}) => {
  try {
    const response = await instance.get("/api/v1/history/wallet", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet summary:", error);
    throw error;
  }
};

export const getPaymentsList = async (accessToken: string, page: number = 1) => {
  try {
    const response = await instance.get("/api/v1/history/payments", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        page: page,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching payments list:", error);
    throw error;
  }
};

export const getInvoiceInfo = async (accessToken: string, uuid: string): Promise<InvoiceInfoResponse> => {
  try {
    const response = await instance.get<InvoiceInfoResponse>(`/api/v1/history/payments/invoice/${uuid}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching invoice info:", error);
    throw error;
  }
};

import React from "react";
import BodyMeasurementServicePagination from "../BodyMeasurementServicePagination";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyMeasurementServiceImageDisplay from "../BodyMeasurementServiceImageDisplay";
import Loader from "../../../loading/Loader";

const Result: React.FC = () => {
  const { measurementData } = useBodyMeasurementServiceContext();

  if (!measurementData) {
    return (
      <div className="flex justify-center w-full p-6">
        <Loader />
      </div>
    );
  }

  const { Shoulder, Chest, Waist, Hips, Neck, UnderChest, Wrist, Knee, UpperBody, LowerBody, ArmLength } = measurementData;

  return (
    <div className="flex flex-col items-center w-full gap-32">
      <div className="flex flex-col items-center w-full gap-10">
        <BodyMeasurementServiceImageDisplay />

        <div className="grid grid-rows-3 gap-1 p-3 w-fit border rounded-md border-closed-shutter-100 *:flex *:justify-between *:gap-1 *:w-full *:*:p-2 *:even:*:bg-gorgonzola-blue-100 *:odd:*:bg-gorgonzola-blue-50 *:*:rounded">
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/shoulder.svg" alt="shoulder icon" />
            </span>
            <span className="min-w-[11.25rem]">Shoulder</span>
            <span className="min-w-36">{Shoulder} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/chest.svg" alt="chest icon" />
            </span>
            <span className="min-w-[11.25rem]">Chest</span>
            <span className="min-w-36">{Chest} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/waist.svg" alt="waist icon" />
            </span>
            <span className="min-w-[11.25rem]">Waist</span>
            <span className="min-w-36">{Waist} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/hips.svg" alt="hips icon" />
            </span>
            <span className="min-w-[11.25rem]">Hips</span>
            <span className="min-w-36">{Hips} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/neck.svg" alt="neck icon" />
            </span>
            <span className="min-w-[11.25rem]">Neck</span>
            <span className="min-w-36">{Neck} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/under-chest.svg" alt="under chest icon" />
            </span>
            <span className="min-w-[11.25rem]">Under Chest</span>
            <span className="min-w-36">{UnderChest} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/wrist.svg" alt="wrist icon" />
            </span>
            <span className="min-w-[11.25rem]">Wrist</span>
            <span className="min-w-36">{Wrist} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/knee.svg" alt="knee icon" />
            </span>
            <span className="min-w-[11.25rem]">Knee</span>
            <span className="min-w-36">{Knee} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/upper-body.svg" alt="upper body icon" />
            </span>
            <span className="min-w-[11.25rem]">Upper Body</span>
            <span className="min-w-36">{UpperBody} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/lower-body.svg" alt="lower body icon" />
            </span>
            <span className="min-w-[11.25rem]">Lower Body</span>
            <span className="min-w-36">{LowerBody} cm</span>
          </div>
          <div>
            <span>
              <img src="/assets/image/free-trial/body-measurment/measurement-result-icon/arm-length.svg" alt="arm length icon" />
            </span>
            <span className="min-w-[11.25rem]">Arm Length</span>
            <span className="min-w-36">{ArmLength} cm</span>
          </div>
        </div>

        <BodyMeasurementServicePagination handlerButtonText={"Try Again"} isMeasurementSuccessed={true} />
      </div>
    </div>
  );
};

export default Result;

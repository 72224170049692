import React, { useState } from "react";
import { motion } from "framer-motion";
import { PlanUsageProps, Plan } from "../../utilities/types/plan/types";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";
import { InfoCircle } from "iconsax-react";
import FreePlanModal from "./FreePlanModal";

const PlanUsage: React.FC<PlanUsageProps> = ({ data }) => {
  const { userInfo } = useGlobalInfo();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  if (!data) {
    return <div className="text-center text-closed-shutter-300">Loading plan usage data...</div>;
  }

  if (data.length === 0) {
    return <div className="text-center text-closed-shutter-300">No active plans. Consider upgrading to access more features.</div>;
  }

  const renderProgressBar = (plan: Plan) => {
    const usagePercentage = plan.apis[0].remaining_requests_pre;

    return (
      <div className="relative w-full h-2.5 rounded-full bg-white">
        <div
          className={`absolute inset-0 h-full rounded-full ${
            plan.apis[0].name === "Size Measurement" ? "bg-[linear-gradient(90deg,_#3491FA_44.5%,_#004594_100%)]" : "bg-[linear-gradient(90deg,_#F7BA1E_44.5%,_#AB7B00_100%)]"
          }`}
          style={{ width: `${usagePercentage}%` }}
          aria-label={`${usagePercentage}% of ${plan.apis[0].name} used`}
        />

        <div className="absolute inset-0 w-[calc(100%-2rem)]">
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: `${Math.min(usagePercentage, 100)}%` }}
            transition={{ duration: 1, delay: 1 }}
            className="absolute w-full -translate-y-full bottom-3.5"
          >
            <div className="relative -translate-x-1/2 w-fit">
              <img src="/assets/image/plan/persentage_bg.svg" alt="percentage background" className="w-16" />
              <span className="absolute inset-0 flex items-center justify-center text-xs font-bold">{usagePercentage}%</span>
            </div>
          </motion.div>
        </div>
      </div>
    );
  };

  const handleOpenModal = (plan: Plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  return (
    <div className="flex flex-col gap-4">
      {data.map((item, index) => (
        <div key={index} className="flex flex-col gap-2">
          <div className={`flex flex-col gap-10 p-3 rounded-md ${item.plan.apis[0].name === "Size Measurement" ? "bg-[#3491fa1a]" : "bg-[#f7ba1e1a]"}`}>
            <div className="relative flex flex-col w-full gap-4 ps-3">
              <span className={`absolute inset-0 w-1 h-full rounded-lg ${item.plan.apis[0].name === "Size Measurement" ? "bg-[#3491FA]" : "bg-[#F7BA1E]"}`} />

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="*:size-5">
                    {item.plan.apis[0].name === "Size Measurement" ? (
                      <img src="/assets/image/plan/size_measurement_icon.svg" alt="size measurement icon" />
                    ) : (
                      <img src="/assets/image/plan/auto_tagging_icon.svg" alt="clothes recognition icon" />
                    )}
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <h4 className="text-base font-bold capitalize text-closed-shutter-300">{item.plan.apis[0].name}</h4>
                    {item.plan.is_free_plan ? (
                      <div
                        className="w-[3.25rem] flex justify-center p-1 gap-1 items-center text-closed-shutter-300 hover:text-gorgonzola-blue-300 cursor-pointer duration-600 bg-white rounded-full"
                        onClick={() => handleOpenModal(item.plan)}
                      >
                        <span className="text-2xs ">Free</span>
                        <InfoCircle className="size-4" variant="Broken" />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex items-center justify-between text-base font-bold">
                  <div className="flex items-center gap-1">
                    <span className={`${item.plan.apis[0].name === "Size Measurement" ? "text-[#3491FA]/50" : "text-[#F7BA1E]/50"}`}>{item.plan.apis[0].total_requests}</span>
                    <span className={`${item.plan.apis[0].name === "Size Measurement" ? "text-[#3491FA]" : "text-[#F7BA1E]"}`}>
                      / {item.plan.apis[0].total_requests - item.plan.apis[0].remaining_requests}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              {renderProgressBar(item.plan)}

              <span className="text-closed-shutter-300 text-2xs">
                Dear {userInfo?.first_name}, {item.plan.apis[0].total_requests - item.plan.apis[0].remaining_requests} requests have been used so far
              </span>
            </div>
          </div>
        </div>
      ))}

      {selectedPlan && <FreePlanModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} freeRequests={selectedPlan.apis[0].total_requests} />}
    </div>
  );
};

export default PlanUsage;
